export default {accountinfo: {getAccoutInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/accountinfo'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAccountInfoUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/accountinfo'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getServiceIPAddressesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/serviceipaddresses'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},adminauth: {authenticateAdminApiUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/adminapi/authenticate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      if (parameters['Authorization']) {
      headers['Authorization'] = parameters['Authorization'];
      }

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},applications: {listApplicationInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applicationinfo'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAdminApiApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/adminapi'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createAdminApiApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/adminapi'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAdminApiSharedSecretUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/regenerate/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAdminApiApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAdminApiApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeAdminApiApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/adminapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAuthApiApplicationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/authapi'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createAuthApiApplicationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/authapi'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAuthApiApplicationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/authapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAuthApiApplicationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/authapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeAuthApiApplicationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/applications/authapi/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listApplicationTemplatesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/applications/templates'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},directories: {getDirectoryAttrMappingUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/attributemappings/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listDirectoriesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/directories'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectoryUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectoryAttrMappingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/attributemappings`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectorySyncsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/directorysyncs`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGroupFiltersUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/groupfilters`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSearchBasesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directories/{id}/searchbases`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectorySyncStatusInfoUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directorysyncinfo/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getDirectorySyncUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/directorysyncs/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGroupFilterUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/groupfilters/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSearchBaseUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/searchbases/{searchbaseid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},otps: {contactVerificationAuthenticateUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/contact/verification/authenticate'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },contactVerificationChallengeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/contact/verification/challenge'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOTPUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/otps'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},entitlements: {getSubscriberAccountActiveEntitlementsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/entitlements/info'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},face: {deleteFaceUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["faceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/face/{faceid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },sendFaceActivationEmailUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["faceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/face/{faceid}/activation`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateFaceUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/face`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createFaceUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/face`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getFacesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/faces`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserFaceSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/settings/face`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},fidotokens: {startCreateFIDOTokenUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/fidotokens/challenge/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },completeCreateFIDOTokenUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/fidotokens/complete/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getFIDOTokenUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/fidotokens/{fidoid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateFIDOTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["fidoid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/fidotokens/{fidoid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteFIDOTokenUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["fidoid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/fidotokens/{fidoid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},grids: {createUnassignedGridsUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/grids'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGridPropertiesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/grids/properties'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deliverAssignedGridByEmailUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["gridid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/grids/{gridid}/email`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSingleGridExportUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/grids/{gridid}/export`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignGridByIdUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["gridserialnumber","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/grids/{gridserialnumber}/assign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignGridUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/grids/{id}/unassign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedGridsPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/gridspaged/unassigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignGridBySerialNumberUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{id}/grids/assign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGridBySerialNumberUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/grids/sernum/{sernum}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGridUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/grids/{gridid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUnassignedGridUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["gridid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/grids/{gridid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGridUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["gridid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/grids/{gridid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeGridStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["gridid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/grids/{gridid}/changestate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedGridsPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/gridspaged/assigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createGridUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/users/{userid}/grids`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},groups: {groupsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createGroupUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/groups'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },groupByExternalIdUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/groups/externalid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },groupUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGroupUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteGroupUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/groups/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },groupsPagedUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/groupspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserAuthorizationGroupAssociationsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/userauthorizationgroup/{userid}/groups`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},identityproviders: {listIdentityProvidersUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/identityproviders'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listOidcIdentityProvidersUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/identityproviders/oidc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createOidcIdentityProviderUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/identityproviders/oidc'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },fetchOidcConfigurationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/identityproviders/oidc/configuration'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOidcIdentityProviderUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/identityproviders/oidc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateOidcIdentityProviderUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/identityproviders/oidc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteOidcIdentityProviderUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/identityproviders/oidc/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listSamlIdentityProvidersUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/identityproviders/saml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSamlIdentityProviderUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/identityproviders/saml'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSamlIdentityProviderUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/identityproviders/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSamlIdentityProviderUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/identityproviders/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSamlIdentityProviderUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/identityproviders/saml/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},userrbasettings: {ipLocationFromIpAddressUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/iplocations'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listUserExpectedLocationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/expectedlocations`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setUserExpectedLocationsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/expectedlocations`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listUserLocationsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userlocations`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserLocationsUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userlocations`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserRbaSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userrbasettings`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateUserRbaSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userrbasettings`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},oauthroles: {listOAuthRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/oauthroles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserOAuthRoleAssociationsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/useroauthrole/{userid}/oauthroles`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},organizations: {createOrganizationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/organizations'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOrganizationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/organizations/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateOrganizationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/organizations/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeOrganizationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/organizations/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createUserOrganizationAssociationUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["orgid","userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/organizations/{orgid}/users/{userid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserOrganizationAssociationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["orgid","userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/organizations/{orgid}/users/{userid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },organizationsPagedUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/organizationspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},roles: {listSiteRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/roles'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSiteRoleUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/roles/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserRolesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserSiteRoleAssociationUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserSiteRoleAssociationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","roleid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserSiteRoleAssociationUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","roleid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/roles/{roleid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},smartcredentials: {listSCDefnsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/scdefns'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSCDefnByNameUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/scdefns/name'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },listAllowedSCDefnsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scdefns/users/{userId}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSCDefnUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/scdefns/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createSmartCredentialUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/smartcredentials'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },exportCertificateUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/certificate/{id}/export/{format}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },holdCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/certificate/{id}/hold`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },revokeCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/certificate/{id}/revoke`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unholdCertificateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/certificate/{id}/unhold`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSmartCredentialBySerialNumberUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/serialnumber/{sernum}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getSmartCredentialUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + '?' + ["revocationInfo"].map(key => `${key}=${parameters[key]}`).join('&')
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteSmartCredentialUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },activateSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}/activate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeSmartCredentialStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}/changestate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },completeSignSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}/completesign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startSignSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}/startsign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}/unassign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unblockSmartCredentialUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/smartcredentials/{id}/unblock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},settings: {getEntrustSTAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/entrustst'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateEntrustSTAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/entrustst'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getFaceAccountSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/face/account'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setFaceAccountSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/face/account'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGeneralSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/general'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGeneralSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/general'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getGoogleAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/google'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateGoogleAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/google'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getPasswordResetSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/passwordreset'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updatePasswordResetSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/settings/passwordreset'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getOTPAuthenticatorSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v3/settings/otp'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateOTPAuthenticatorSettingsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/settings/otp'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},users: {syncUserUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/syncusers/sync'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unsyncUserUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/syncusers/unsync'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateUserStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{id}/state`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unlockUserUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{id}/unlock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createMagicLinkUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/magiclink`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteMagicLinkUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/magiclink`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserAOrganizationAssociationsUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/organizations`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createUserUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/users'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userByExternalIdUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/users/externalid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateUsersUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/users/multiple'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createUsersUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/users/multiple'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUsersUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/users/multiple'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userByUseridUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v3/users/userid'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },userUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v3/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateUserUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v3/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v3/users/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },usersPagedUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/userspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},tenants: {getSpIdentityProviderUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/spidentityprovider`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setSpIdentityProviderUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tenants/{id}/spidentityprovider`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCreateTenantAsyncResultUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/async/tenants/{id}/createresult`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getCreateTenantAsyncStatusUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/async/tenants/{id}/createstatus`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTenantUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/tenants'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getEntitlementUsageInfoUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/tenants/entitlements/usage'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeTenantUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },lockTenantUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}/lock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unlockTenantUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{id}/unlock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantEntitlementsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setTenantEntitlementUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tenantid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantEntitlementUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteTenantEntitlementUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tenantid","type","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v4/tenants/{tenantid}/entitlements/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTenantsPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v4/tenantspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTenantAsyncUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v5/async/tenants'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},tokens: {getTokenBySerialNumberUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/sernum/{sernum}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getTokenUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteTokenUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },completeActivateTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/activation`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },startActivateTokenUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/activation`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignTokenByIdUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/assign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyAssignedTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/assigned`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },changeTokenStateUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/changestate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },resetTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/reset`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/unassign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unlockTokenUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["tokenid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/tokens/{tokenid}/unlock`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignedTokenPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/tokenspaged/assigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },unassignedTokenPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/tokenspaged/unassigned'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },assignTokenBySerialNumberUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{id}/tokens/assign`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTokenUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","type","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/tokens/{type}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},resourcerules: {getTransactionRulesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/transactionrules'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAuthenticationFlowsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v2/authenticationflows'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createAuthenticationFlowUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/authenticationflows'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getAuthenticationFlowUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/authenticationflows/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateAuthenticationFlowUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/authenticationflows/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeAuthenticationFlowUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/authenticationflows/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResourceRulesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v2/resourcerules'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createResourceRuleUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/resourcerules'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResourceRulesForResourceUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/resourcerules/resource/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getResourceRuleUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v2/resourcerules/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateResourceRuleUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/resourcerules/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeResourceRuleUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v2/resourcerules/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},userattributes: {listUserAttributesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/userattributes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createUserAttributeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/userattributes'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserAttributeUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/userattributes/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },modifyUserAttributeUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/userattributes/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserAttributeUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/userattributes/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},kba: {getKbaQuestionsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/userquestions/{locale}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserKbaChallengesUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/kbachallenges`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserKbaUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userquestions`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateKnowledgeBasedAuthenticatorUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userquestions`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },addKnowledgeBasedAuthenticatorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userquestions`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserKbaUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userquestions`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserKbaQuestionUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/userquestions/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},devices: {getCachedActiveSyncDevicesUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/activesyncdevices`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateActiveSyncDevicesUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/activesyncdevices`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getActiveSyncDevicesUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/activesyncdevices`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },removeActiveSyncDeviceUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","deviceid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/activesyncdevices/{deviceid}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},machineauth: {listMachineAuthenticatorsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/machines`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createMachineAuthenticatorUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/machines`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteMachineAuthenticatorUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/machines/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},passwords: {sendPasswordExpiryNotificationUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/password/notify`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },validateUserPasswordUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/password/validate`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserPasswordUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/passwords`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },setUserPasswordUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/passwords`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteUserPasswordUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/passwords`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },getUserPasswordSettingsUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/settings/password`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},tempaccesscodes: {getTempAccessCodeUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/tempaccesscode`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createTempAccessCodeUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/tempaccesscode`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteTempAccessCodeUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["userid","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/users/{userid}/tempaccesscode`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},webhooks: {getWebhooksUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + '/api/web/v1/webhooks'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },createWebhookUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v1/webhooks'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },testWebhookUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/test/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },readWebhookUsingGET: async props => {
      const { parameters = {}, baseUrl } = props;

      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'GET',
        headers
      };

      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },updateWebhookUsingPUT: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'PUT',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },deleteWebhookUsingDELETE: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["id","Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + `/api/web/v1/webhooks/{id}`.replace(/\{([^}]+)\}/g, (_, key) => parameters[key])
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'DELETE',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }},reports: {auditEventPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/reports/auditeventspaged'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    },siemAuditEventPageUsingPOST: async props => {
      const { parameters = {}, baseUrl } = props;

      
      const bodyParameterName = Object.keys(parameters).filter(key => !["Authorization"].includes(key));
      const requestBodyName = bodyParameterName.length > 0 ? bodyParameterName[0] : null;
      

      const path = encodeURI(baseUrl
        + '/api/web/v2/reports/auditeventspaged/siem'
        + ''
      );

      const headers = {
        'Accept': 'application/json',
        
      };

      
      headers['Authorization'] = parameters['Authorization'];
      

      const options = {
        method: 'POST',
        headers
      };

      
      if (requestBodyName && parameters[requestBodyName]) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(parameters[requestBodyName]);
      }
      

      if (props?.signal) {
        options.signal = props.signal;
      }

      const response = await fetch(path, options);

      const resp = await response.text();
      let data;

      try {
        data = JSON.parse(resp);
      } catch (e) {
        data = resp;
      }

      if (response.ok) {
        return data;
      }

      throw { status: response.status, response: data };
    }}}