//      
import * as React from 'react';

import { alpha, makeStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import CreditCardSharpIcon from '@material-ui/icons/CreditCardSharp';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Material-UI Icons
// =============================================================================
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloudIcon from '@material-ui/icons/Cloud';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupPolicyIcon from '@material-ui/icons/AccountBalance';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LockIcon from '@material-ui/icons/Lock';
import Palette from '@material-ui/icons/Palette';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PieChartIcon from '@material-ui/icons/PieChart';
import Print from '@material-ui/icons/Print';
import Queue from '@material-ui/icons/Queue';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@edc/react-ui/Icons/Settings';
import SimCardIcon from '@material-ui/icons/SimCard';
import StorageIcon from '@material-ui/icons/Storage';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import WebIcon from '@material-ui/icons/Web';
import WidgetsIcon from '@material-ui/icons/Widgets';

// Redux / Utility functions
// =============================================================================
import { useSelector } from 'react-redux';
import { filterByBundleFeatureName } from 'src/lib/entitlements';
import { getHighestRankedBundle } from 'src/ducks/bundleFeatures';
import { getIsRootSpAccount } from 'src/ducks/theme';
import { isFleetOnlyIssuanceTenant } from 'src/ducks/settings';
import {
  ROLE_TYPE_ACCOUNT_MANAGER,
  ROLE_TYPE_SITE_ADMINISTRATOR,
  SERVICE_PROVIDER_API_ACCOUNT_ONBOARDING_ROLE_ID,
  SITE_ISSUANCE_BASIC_SUPPORT_ROLE_ID,
  SITE_ISSUANCE_ENHANCED_SUPPORT_ROLE_ID
} from 'src/lib/constants';
import { userHasRole, userHasPermissionsForRoleType, getRoleByType, isRole } from 'src/ducks/permissions';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';

import t from 'src/i18n';

// Flow Types
// =============================================================================
                                                     
                                                             
                                                                               
                                                               
import { isMobile } from 'react-device-detect';

// DrawerList Component
// =============================================================================
const DrawerList = () => {
  const currentAdminRole = useSelector((state            ) =>
    getRoleByType(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR)
  );
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const isIssuanceAccount = useSelector((state            ) => state.themes.subscriber.issuanceAccount);
  const isIssuanceBundle = useSelector((state            ) => state.settings.bundles.hasIssuance);
  const isSiteAdmin = useSelector((state            ) => userHasRole(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR));
  const isSPAdmin = useSelector((state            ) => userHasRole(state.permissions, ROLE_TYPE_ACCOUNT_MANAGER));
  const isRootSPAccount = useSelector((state            ) => getIsRootSpAccount(state.themes));
  const smartLoginEnabled = useSelector((state            ) => state.themes.subscriber.smartLoginEnabled);
  const bundleFeatures = useSelector((state            ) => getHighestRankedBundle(state));
  const permissions = useSelector((state            ) => state.permissions);
  const showMyActivities = useSelector(
    (state            ) => state.settings.self.userTabConfiguration?.showMyActivities
  );
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));

  const entitlementBundles = useSelector(state => state.settings.bundles);
  const userPortalMenuItems = isAuthenticationAccount ? getUserPortalMenuItems(showMyActivities) : null;
  const subscriberPortalMenuItems = getSubscriberPortalMenuItems(
    currentAdminRole,
    isAuthenticationAccount,
    isIssuanceAccount,
    isIssuanceBundle,
    isSiteAdmin,
    isSPAdmin,
    smartLoginEnabled,
    bundleFeatures,
    permissions,
    entitlementBundles,
    isFleetOnlyTenant
  );

  const serviceProviderPortalMenuItems = getServiceProviderPortalMenuItems(isSPAdmin, isRootSPAccount, permissions);

  return (
    <List>
      {userPortalMenuItems}

      {isSiteAdmin && userPortalMenuItems && <Divider />}

      {subscriberPortalMenuItems}

      {isSPAdmin && <Divider />}

      {serviceProviderPortalMenuItems}

      {isIssuanceAccount && <Divider />}
    </List>
  );
};

export default DrawerList;

// Helper Functions
// =============================================================================

                    
                    
             
                                 
                   
                 
  

/**
 * Material-UI Nested List Component - https://material-ui.com/components/lists/#nested-list
 */
const NestedListItem = (props             ) => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const { disabled, icon, id, nestedItems, primary } = props;

  const [open, setOpen] = React.useState(false);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  return (
    <>
      <ListItem id={id} button disabled={disabled} onClick={() => setOpen(!open)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{primary}</ListItemText>
        {disabled ? null : open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>{nestedItems}</List>
      </Collapse>
    </>
  );
};

NestedListItem.defaultProps = {
  disabled: false
};

/**
 * Function that retrieves the menu items for the User Portal
 * @return {[Array]}         Returns an array of menu items.
 */
const getUserPortalMenuItems = showMyActivities => {
  const menuItems = [];

  // My Profile
  menuItems.push(
    <ListItemLink
      id="app-parts-header-myProfile"
      key="app-parts-header-myProfile"
      icon={<PersonIcon />}
      inset={false}
      primary={t('UserProfileMenu.myprofile')}
      to="/myprofile"
    />
  );

  if (showMyActivities) {
    menuItems.push(
      // My Activity
      <ListItemLink
        id="app-parts-header-myActivity"
        key="app-parts-header-myActivity"
        icon={<PieChartIcon />}
        inset={false}
        primary={t('UserProfileMenu.myreports')}
        to="/myreports"
      />
    );
  }

  return menuItems;
};

/**
 * Function that retrieves the menu items for the Subscriber Portal
 * @param  {Boolean} isAuthenticationAccount Whether or not this account is an authentication account
 * @param  {Boolean} isIssuanceAccount Whether or not this account is an issuance account
 * @param  {Boolean} isIssuanceBundle Whether or not this account has an issuance bundle
 * @param  {Boolean} isSiteAdmin Whether or not the user is a SITE_ADMINISTRATOR
 * @param  {Boolean} isSPAdmin Whether or not the user is a SP_ADMINISTRATOR
 * @param  {Boolean} smartLoginEnabled Whether or not the account has smart login enabled
 * @param  {BundleFeature} bundleFeatures features enabled for this account
 * @param  {[type]}  permissions Redux variable containing all of the user's permissions
 * @return {[Array]}             Returns an array of menu items.
 */
const getSubscriberPortalMenuItems = (
  currentAdminRole      ,
  isAuthenticationAccount         ,
  isIssuanceAccount         ,
  isIssuanceBundle         ,
  isSiteAdmin         ,
  isSPAdmin         ,
  smartLoginEnabled         ,
  bundleFeatures                ,
  permissions                 ,
  entitlementBundles        ,
  isFleetOnlyTenant         
) => {
  if (!isSiteAdmin) {
    return [];
  }

  if (isAuthenticationAccount) {
    return getAuthenticationSubscriberPortalMenuItems(
      currentAdminRole,
      isSPAdmin,
      isIssuanceAccount,
      isIssuanceBundle,
      smartLoginEnabled,
      bundleFeatures,
      permissions
    );
  } else {
    return getIssuanceSubscriberPortalMenuItems(
      currentAdminRole,
      isSPAdmin,
      isIssuanceBundle,
      entitlementBundles,
      isFleetOnlyTenant
    );
  }
};

const getAuthenticationSubscriberPortalMenuItems = (
  currentAdminRole      ,
  isSPAdmin         ,
  isIssuanceAccount         ,
  isIssuanceBundle         ,
  smartLoginEnabled         ,
  bundleFeatures                ,
  permissions                 
) => {
  const menuItems = [];

  // Dashboard
  const dashboard = <GetDashboardMenuItem key="app-parts-header-reports" />;
  if (dashboard) {
    menuItems.push(dashboard);
  }

  // Members
  const members = <GetMembersMenuItems key="app-parts-header-members" />;
  if (members) {
    menuItems.push(members);
  }

  // Security
  const security = getSecurityMenuItems(
    currentAdminRole,
    true,
    isIssuanceAccount,
    smartLoginEnabled,
    bundleFeatures,
    permissions
  );

  if (security) {
    menuItems.push(security);
  }

  // Resources
  const resource = getResourceMenuItems(
    currentAdminRole,
    true,
    isIssuanceAccount,
    isIssuanceBundle,
    smartLoginEnabled,
    bundleFeatures,
    permissions
  );

  if (resource) {
    menuItems.push(resource);
  }

  // Configuration
  const configuration = <GetConfigurationMenuItems key="app-parts-header-configurations" />;
  if (configuration) {
    menuItems.push(configuration);
  }

  // Settings
  const settings = <GetSettingsMenuItems key="app-parts-header-settings" />;
  if (settings) {
    menuItems.push(settings);
  }

  // Customization
  const customization = <GetCustomizationMenuItems key="app-parts-header-customize" />;
  if (customization) {
    menuItems.push(customization);
  }

  const bulk = <GetBulkMenuItems key="app-parts-header-bulk" />;
  if (bulk) {
    menuItems.push(bulk);
  }

  const reports = <GetReportsMenuItems key="app-parts-header-export-reports" />;
  if (reports) {
    menuItems.push(reports);
  }

  const scheduledTasks = getScheduledTasksMenuItems(isSPAdmin, true, permissions);
  if (scheduledTasks) {
    menuItems.push(scheduledTasks);
  }

  return menuItems;
};

const getIssuanceSubscriberPortalMenuItems = (
  currentAdminRole      ,
  isSPAdmin         ,
  isIssuanceBundle         ,
  entitlementBundles        ,
  isFleetOnlyTenant         
) => {
  const menuItems = [];

  // Dashboard
  const dashboard = <GetDashboardMenuItem key="app-parts-header-reports" />;
  if (dashboard) {
    menuItems.push(dashboard);
  }

  // Credentials
  if (isIssuanceBundle) {
    const credentials = <GetCredentialsMenuItem key="app-parts-header-credentials" />;
    if (credentials) {
      menuItems.push(credentials);
    }
  }

  // Credential Designs
  if (isIssuanceBundle) {
    const credentialDesigns = <GetCredentialDesignsMenuItem key="app-parts-header-credentialDesigner" />;
    if (credentialDesigns) {
      menuItems.push(credentialDesigns);
    }
  }

  // Workflow Designs
  if (isIssuanceBundle) {
    const workflowDesigns = <GetWorkflowDesignsMenuItem key="app-parts-header-workflowDesigner" />;
    if (workflowDesigns) {
      menuItems.push(workflowDesigns);
    }
  }

  // External Databases
  if (isIssuanceBundle) {
    const externalDatabases = <GetExternalDatabasesMenuItem key="app-parts-header-externalDatabases" />;
    if (externalDatabases) {
      menuItems.push(externalDatabases);
    }
  }

  // Mobile Flash Pass Designs
  if (isIssuanceBundle) {
    const mobileFlashPassDesigns = <GetMobileFlashPassDesignsMenuItem key="app-parts-header-mobileFlashPassDesigner" />;
    if (mobileFlashPassDesigns) {
      menuItems.push(mobileFlashPassDesigns);
    }
  }

  //Smart card
  if (isIssuanceBundle) {
    let isSmartCardEnabled = false;
    if (entitlementBundles.features) {
      for (let i = 0; i < entitlementBundles.features.length; i++) {
        if (entitlementBundles.features[i] && entitlementBundles.features[i].smartCard) {
          isSmartCardEnabled = entitlementBundles.features[i].smartCard[0].enabled === 'true';
        }
      }
    }
    if (isSmartCardEnabled) {
      if (!isFleetOnlyTenant) {
        const smartCard = <GetSmartCardMenuItem />;
        if (smartCard) {
          menuItems.push(smartCard);
        }
      }
    }
  }

  // Printers
  const printer = <GetPrinterMenuItem key="app-parts-header-printers" />;
  if (printer) {
    menuItems.push(printer);
  }

  // Print Queue
  const printQueue = <GetPrintQueueMenuItem key="app-parts-header-print-queue" />;
  if (printQueue) {
    menuItems.push(printQueue);
  }

  if (isIssuanceBundle) {
    const bulk = <GetBulkMenuItems key="app-parts-header-bulk" />;
    if (bulk) {
      menuItems.push(bulk);
    }
  }

  const adminMenuItems = <GetAdministrationMenuItems />;
  if (adminMenuItems) {
    menuItems.push(adminMenuItems);
  }

  return menuItems;
};

const GetPrinterMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const hasFleetManagement = useSelector((state            ) => state.settings.bundles.hasFleetManagement);
  const tier = useSelector((state            ) => state.themes.subscriber.tier);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['PRINTERS'])) {
    return null;
  } else {
    const icon = !isAuthenticationAccount ? <Print /> : null;
    return (
      <ListItemLink
        id="app-parts-header-printers"
        icon={icon}
        inset={isAuthenticationAccount}
        primary={
          hasFleetManagement && tier === 1 ? t('resourceMenu.fleetManagementPrinters') : t('resourceMenu.printers')
        }
        to="/printers"
      />
    );
  }
};

const GetSmartCardMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SMARTCARDS'])) {
    return null;
  } else {
    const icon = !isAuthenticationAccount ? <SimCardIcon /> : null;
    return (
      <ListItemLink
        id="app-parts-header-smartcard"
        icon={icon}
        inset={isAuthenticationAccount}
        primary={t('breadCrumbs.smartCardProfiles')}
        to="/smartcard"
      />
    );
  }
};

const GetCredentialDesignsMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);
  const isDirectTenantLocked = useSelector((state            ) => state.settings.bundles.directTenantLocked);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  if (isFleetOnlyTenant) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['CREDENTIALDESIGNS'])) {
    return null;
  } else {
    const icon =
      !isAuthenticationAccount && isDirectTenant && isDirectTenantLocked ? (
        <LockIcon />
      ) : !isAuthenticationAccount ? (
        <Palette />
      ) : null;
    return (
      <ListItemLink
        id="app-parts-header-credentialDesigner"
        icon={icon}
        inset={isAuthenticationAccount}
        disabled={isDirectTenant && isDirectTenantLocked}
        primary={t('CredentialDesigns.title')}
        to={'/credentialdesigns'}
      />
    );
  }
};

const GetWorkflowDesignsMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);
  const isDirectTenantLocked = useSelector((state            ) => state.settings.bundles.directTenantLocked);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  if (isFleetOnlyTenant) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['ENROLLMENTDESIGNS'])) {
    return null;
  } else {
    const icon =
      !isAuthenticationAccount && isDirectTenant && isDirectTenantLocked ? (
        <LockIcon />
      ) : !isAuthenticationAccount ? (
        <WebIcon />
      ) : null;
    return (
      <ListItemLink
        id="app-parts-header-workflowDesigner"
        icon={icon}
        inset={isAuthenticationAccount}
        disabled={isDirectTenant && isDirectTenantLocked}
        primary={t('EnrollmentDesigns.title')}
        to={'/enrollmentdesigns'}
      />
    );
  }
};

const GetExternalDatabasesMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const entitlementBundles = useSelector((state            ) => state.settings.bundles);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));

  const isEnabled = entitlementBundles.features[0].externalDatabase[0].enabled === 'true';

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  if (isFleetOnlyTenant) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SETTINGS']) || !isEnabled) {
    return null;
  } else {
    const icon = !isAuthenticationAccount ? <StorageIcon /> : null;
    return (
      <ListItemLink
        id="app-parts-header-externalDatabase"
        icon={icon}
        inset={isAuthenticationAccount}
        primary={t('ExternalDatabases.title')}
        to={'/externaldatabases'}
      />
    );
  }
};

const GetMobileFlashPassDesignsMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }
  if (isFleetOnlyTenant || isDirectTenant) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['CREDENTIALDESIGNS'])) {
    return null;
  } else {
    const icon = !isAuthenticationAccount ? <DeveloperModeIcon /> : null;
    return (
      <ListItemLink
        id="app-parts-header-mobileFlashPassDesigner"
        icon={icon}
        inset={isAuthenticationAccount}
        primary={t('MobileFlashPassDesigns.title')}
        to={'/mobileflashpassdesigns'}
      />
    );
  }
};

const GetCredentialsMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);
  const isDirectTenantLocked = useSelector((state            ) => state.settings.bundles.directTenantLocked);

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['CREDENTIALDESIGNS'])) {
    return null;
  }

  if (isFleetOnlyTenant) {
    return null;
  }
  const icon =
    !isAuthenticationAccount && isDirectTenant && isDirectTenantLocked ? (
      <LockIcon />
    ) : !isAuthenticationAccount ? (
      <CreditCardSharpIcon />
    ) : null;
  return (
    <ListItemLink
      id="app-parts-header-credentials"
      icon={icon}
      inset={isAuthenticationAccount}
      disabled={isDirectTenant && isDirectTenantLocked}
      primary={t('resourceMenu.credentials')}
      to={'/credentials'}
    />
  );
};

const GetPrintQueueMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));

  if (isFleetOnlyTenant) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['ISSUANCE'])) {
    return null;
  } else {
    const icon = !isAuthenticationAccount ? <Queue /> : null;
    return (
      <ListItemLink
        id="app-parts-header-print-queue"
        icon={icon}
        inset={isAuthenticationAccount}
        primary={t('PrintJobs.title')}
        to={'/printqueue'}
      />
    );
  }
};

const GetWebhooksMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isIssuanceBundle = useSelector((state            ) => state.settings.bundles.hasIssuance);
  const entitlementBundles = useSelector((state            ) => state.settings.bundles);
  const isWebHookEnabled =
    entitlementBundles.features &&
    entitlementBundles.features.length &&
    entitlementBundles.features[0].webHook.length &&
    entitlementBundles.features[0].webHook[0].enabled === 'true';

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['WEBHOOKS'])) {
    return null;
  }

  if (isAuthenticationAccount || (isIssuanceBundle && isWebHookEnabled)) {
    return (
      <ListItemLink
        id="app-parts-header-webhooks"
        key="app-parts-header-webhooks"
        inset2={!isAuthenticationAccount}
        primary={t('webhooks.pageTitle')}
        to="/webhooks"
      />
    );
  } else {
    return null;
  }
};

const GetDashboardMenuItem = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['REPORTS'])) {
    return null;
  }

  return (
    <ListItemLink
      id="app-parts-header-reports"
      icon={<HomeIcon />}
      inset={false}
      primary={t('Dashboard.menu_dashboard')}
      to="/dashboard"
    />
  );
};

const GetReportsMenuItems = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);

  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['EXPORTREPORTS', 'ARCHIVES'])) {
    return null;
  }

  const icon = isAuthenticationAccount ? <AssignmentIcon /> : null;
  return (
    <ListItemLink
      id="app-parts-header-export-reports"
      icon={icon}
      inset={!isAuthenticationAccount}
      inset2={!isAuthenticationAccount}
      primary={t('breadCrumbs.reports')}
      to="/reports"
    />
  );
};

const getScheduledTasksMenuItems = (
  isSPAdmin         ,
  isAuthenticationAccount         ,
  permissions                 
) => {
  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SCHEDULEDTASKS'])) {
    return null;
  }

  if (!isSPAdmin) {
    return null;
  }

  const icon = isAuthenticationAccount ? <ScheduleIcon /> : null;
  return (
    // Scheduled Tasks
    <ListItemLink
      id="app-parts-header-scheduled-tasks"
      key="app-parts-header-scheduled-tasks"
      icon={icon}
      inset={!isAuthenticationAccount}
      inset2={!isAuthenticationAccount}
      primary={t('tasks.title')}
      to="/scheduledTasks"
    />
  );
};

const GetBulkMenuItems = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const isIssuanceAccount = useSelector((state            ) => state.themes.subscriber.issuanceAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));

  if (
    !userHasPermissionsForRoleType(
      permissions,
      ROLE_TYPE_SITE_ADMINISTRATOR,
      [
        isAuthenticationAccount && 'BULKGROUPS',
        isAuthenticationAccount && 'BULKHARDWARETOKENS',
        isAuthenticationAccount && 'BULKSMARTCARDS',
        isAuthenticationAccount && 'BULKIDENTITYGUARD',
        isAuthenticationAccount && 'BULKUSERS',
        isIssuanceAccount && 'BULKENROLLMENTS'
      ].filter(Boolean)
    )
  ) {
    return null;
  }

  if (isFleetOnlyTenant) {
    return null;
  }
  return (
    // Bulk Operations
    <ListItemLink
      id="app-parts-header-bulk"
      icon={<CloudIcon />}
      inset={false}
      primary={t('bulkImport.menu_bulkOperations')}
      to="/bulkoperations"
    />
  );
};

const GetAdministrationMenuItems = () => {
  const currentAdminRole = useSelector((state            ) =>
    getRoleByType(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR)
  );
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));

  if (isMobile && !isAuthenticationAccount) {
    return null;
  }

  let menuItems = [];

  // Members
  const members = <GetMembersMenuItems key="app-parts-header-members" />;
  if (members) {
    menuItems.push(members);
  }

  // Resources
  const resource = getResourceMenuItems(currentAdminRole, false, true, true, false, null, permissions);
  if (resource && !isFleetOnlyTenant) {
    menuItems.push(resource);
  }

  // Configurations
  const configuration = <GetConfigurationMenuItems key="app-parts-header-configurations" />;
  if (configuration) {
    menuItems.push(configuration);
  }

  // Settings
  const settings = <GetSettingsMenuItems key="app-parts-header-settings" />;
  if (settings) {
    menuItems.push(settings);
  }

  const reports = <GetIssuanceReportMenu key="app-parts-report-issuance-report" />;
  if (reports) {
    menuItems.push(reports);
  }

  // Customization
  const customization = <GetCustomizationMenuItems key="app-parts-header-customize" />;
  if (customization) {
    menuItems.push(customization);
  }

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <NestedListItem
      id="app-parts-header-administration"
      key="app-parts-header-administration"
      icon={<PeopleIcon />}
      primary={t('MembersMenu.administration')}
      nestedItems={menuItems}
    />
  );
};

const GetMembersMenuItems = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));

  // If the user has none of these permissions then we don't display this menu
  if (
    !userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, [
      'GROUPS',
      'ROLES',
      'USERATTRIBUTES',
      'USERS'
    ])
  ) {
    return null;
  }

  const icon = isAuthenticationAccount ? <PeopleIcon /> : null;

  return (
    <NestedListItem
      id="app-parts-header-members"
      icon={icon}
      primary={t('MembersMenu.members')}
      nestedItems={[
        // Members -> Users
        userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['USERS']) && (
          <ListItemLink
            id="app-parts-header-users"
            key="app-parts-header-users"
            primary={t('MembersMenu.users')}
            inset2={!isAuthenticationAccount}
            to="/users"
          />
        ),

        // Members -> Groups
        userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['GROUPS']) && !isFleetOnlyTenant && (
          <ListItemLink
            id="app-parts-header-groups"
            key="app-parts-header-groups"
            primary={t('MembersMenu.groups')}
            inset2={!isAuthenticationAccount}
            to="/groups"
          />
        ),

        // Members -> Organizations
        isAuthenticationAccount &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['ORGANIZATIONS']) &&
          !isFleetOnlyTenant && (
            <ListItemLink
              id="app-parts-header-organizations"
              key="app-parts-header-organizations"
              primary={t('breadCrumbs.organizations')}
              inset2={!isAuthenticationAccount}
              to="/organizations"
            />
          ),

        // Members -> Roles
        userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['ROLES']) && (
          <ListItemLink
            id="app-parts-header-roles"
            key="app-parts-header-roles"
            primary={t('MembersMenu.roles')}
            inset2={!isAuthenticationAccount}
            to="/roles"
          />
        ),

        // Members -> User attributes
        userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['USERATTRIBUTES']) && (
          <ListItemLink
            id="app-parts-header-attributes"
            key="app-parts-header-attributes"
            primary={t('MembersMenu.attributes')}
            inset2={!isAuthenticationAccount}
            to="/userattributes"
          />
        )
      ]}
    />
  );
};

const getSecurityMenuItems = (
  currentAdminRole      ,
  isAuthenticationAccount         ,
  isIssuanceAccount         ,
  smartLoginEnabled         ,
  bundleFeatures                ,
  permissions                 
) => {
  const icon = isAuthenticationAccount ? <SecurityIcon /> : null;

  const idpProxy = bundleFeatures && filterByBundleFeatureName('idpProxy', bundleFeatures)?.enabled;

  const oauthBundle = bundleFeatures && filterByBundleFeatureName('oAuthV2ApiProtection', bundleFeatures)?.enabled;

  if (
    !userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, [
      'APPLICATIONS',
      'CONTEXTRULES',
      'OAUTHROLES',
      'RESOURCESERVERAPIS',
      'IDENTITYPROVIDERS'
    ])
  ) {
    return null;
  }

  const menu = [];

  if (userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['APPLICATIONS'])) {
    menu.push(
      // Security -> Applications
      <ListItemLink
        id="app-parts-header-applications"
        key="app-parts-header-applications"
        inset2={!isAuthenticationAccount}
        primary={t('resourceMenu.applications')}
        to="/applications"
      />
    );
  }

  if (
    userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['CONTEXTRULES']) &&
    isAuthenticationAccount
  ) {
    // Security -> Authentication Flow
    menu.push(
      <ListItemLink
        id="app-parts-header-authentication-flow"
        key="app-parts-header-authentication-flow"
        primary={t('breadCrumbs.authenticationFlows')}
        to="/authenticationflow"
      />
    );
  }

  if (
    isAuthenticationAccount &&
    oauthBundle &&
    (userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['OAUTHROLES']) ||
      userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['RESOURCESERVERAPIS']))
  ) {
    // Security -> Authorization
    menu.push(
      <ListItemLink
        id="app-parts-header-resourceServers"
        key="app-parts-header-resourceServers"
        inset2={!isAuthenticationAccount}
        primary={t('resourceMenu.resourceServer')}
        to="/authorization"
      />
    );
  }

  if (
    isAuthenticationAccount &&
    idpProxy &&
    userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['IDENTITYPROVIDERS'])
  ) {
    // Security -> Identity Providers
    menu.push(
      <ListItemLink
        id="app-parts-header-identityProviders"
        key="app-parts-header-identityProviders"
        inset2={!isAuthenticationAccount}
        primary={t('resourceMenu.identityProviders')}
        to="/identityproviders"
      />
    );
  }

  if (
    userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['CONTEXTRULES']) &&
    currentAdminRole.groupManagement === 'ALL'
  ) {
    // Security -> Resource Rules
    menu.push(
      <ListItemLink
        id="app-parts-header-resourceRules"
        key="app-parts-header-resourceRules"
        inset2={!isAuthenticationAccount}
        primary={t('resourceMenu.resourceRules')}
        to="/rules"
      />
    );
  }

  if (menu.length === 0) {
    return null;
  }

  return (
    <NestedListItem
      id="app-parts-header-security"
      key="app-parts-header-security"
      icon={icon}
      primary={t('breadCrumbs.security')}
      nestedItems={menu}
    />
  );
};

const getResourceMenuItems = (
  currentAdminRole      ,
  isAuthenticationAccount         ,
  isIssuanceAccount         ,
  isIssuanceBundle         ,
  smartLoginEnabled         ,
  bundleFeatures                ,
  permissions                 
) => {
  // If the user has none of these permissions then disable the Resources menu item
  let disableResourcesMenuItem =
    !userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, [
      'AGENTS',
      'APPLICATIONS',
      'CAS',
      'CONTEXTRULES',
      'DIRECTORIES',
      'DOMAINCONTROLLERCERTS',
      'GRIDS',
      'OAUTHROLES',
      'RESOURCESERVERAPIS',
      'SCDEFNS',
      'RESOURCESERVERAPIS',
      'IDENTITYPROVIDERS',
      'TOKENS',
      'SCIMPROVISIONINGS',
      'WEBHOOKS'
    ]) ||
    isRole(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, SITE_ISSUANCE_BASIC_SUPPORT_ROLE_ID) ||
    isRole(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, SITE_ISSUANCE_ENHANCED_SUPPORT_ROLE_ID);

  if (!isAuthenticationAccount && !disableResourcesMenuItem) {
    disableResourcesMenuItem = !userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, [
      'PRINTERS',
      'AGENTS'
    ]);
  }

  if (disableResourcesMenuItem) {
    return null;
  }

  // the following menu items are only included here when the account is both authentication and issuance.  For an issuance
  // only account they are includes in another part of the menu and for an authentication only account they are not displayed.
  const printMenu =
    isAuthenticationAccount && isIssuanceAccount && isIssuanceBundle ? (
      <GetPrinterMenuItem key="app-parts-header-printers" />
    ) : null;

  const credentialDesignsMenu =
    isAuthenticationAccount && isIssuanceAccount && isIssuanceBundle ? (
      <GetCredentialDesignsMenuItem key="app-parts-header-credentialDesigner" />
    ) : null;

  const workflowDesignsMenu =
    isAuthenticationAccount && isIssuanceAccount && isIssuanceBundle ? (
      <GetWorkflowDesignsMenuItem key="app-parts-header-workflowDesigner" />
    ) : null;

  const credentialsMenu =
    isAuthenticationAccount && isIssuanceAccount && isIssuanceBundle ? (
      <GetCredentialsMenuItem key="app-parts-header-credentials" />
    ) : null;

  const mobileFlashPassDesignsMenu =
    isAuthenticationAccount && isIssuanceAccount && isIssuanceBundle ? (
      <GetMobileFlashPassDesignsMenuItem key="app-parts-header-mobileFlashPassDesigner" />
    ) : null;

  const printQueue =
    isAuthenticationAccount && isIssuanceAccount && isIssuanceBundle ? (
      <GetPrintQueueMenuItem key="app-parts-header-print-queue" />
    ) : null;

  const webhooks = <GetWebhooksMenuItem key="app-parts-header-webhooks" />;

  const icon = isAuthenticationAccount ? <LibraryBooksIcon /> : null;

  const isScimProvisioningEnabled =
    bundleFeatures && filterByBundleFeatureName('scimProvisioning', bundleFeatures)?.enabled;

  const isOIDCEnabled =
    bundleFeatures && filterByBundleFeatureName('openIdConnectApplicationsIntegration', bundleFeatures)?.enabled;

  return (
    <NestedListItem
      id="app-parts-header-resources"
      key="app-parts-header-resources"
      icon={icon}
      primary={t('resourceMenu.resource')}
      nestedItems={[
        // Security -> Applications
        !isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-applications"
            key="app-parts-header-applications"
            inset2={!isAuthenticationAccount}
            primary={t('resourceMenu.applications')}
            to="/applications"
          />
        ),
        // Security -> Authentication Flow
        !isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-authentication-flow"
            key="app-parts-header-authentication-flow"
            inset2={!isAuthenticationAccount}
            primary={t('breadCrumbs.authenticationFlows')}
            to="/authenticationflow"
          />
        ),

        // Security -> Resource Rules
        !isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-resourceRules"
            key="app-parts-header-resourceRules"
            inset2={!isAuthenticationAccount}
            primary={t('resourceMenu.resourceRules')}
            to="/rules"
          />
        ),

        // Resources -> Provisioners
        isAuthenticationAccount &&
          isScimProvisioningEnabled &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SCIMPROVISIONINGS']) && (
            <ListItemLink
              id="app-parts-header-provisioners"
              key="app-parts-header-provisioners"
              primary={t('resourceMenu.provisioners')}
              to="/provisioners"
            />
          ),

        // Resources -> Directories
        isAuthenticationAccount &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['DIRECTORIES']) && (
            <ListItemLink
              id="app-parts-header-directories"
              key="app-parts-header-directories"
              primary={t('resourceMenu.directories')}
              to="/directories"
            />
          ),

        // Resources -> Gateways
        userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['AGENTS']) && (
          <ListItemLink
            id="app-parts-header-gateways"
            key="app-parts-header-gateways"
            inset2={!isAuthenticationAccount}
            primary={t('resourceMenu.gateways')}
            to="/gateways"
          />
        ),

        // Resources -> Hardware Tokens
        isAuthenticationAccount &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['TOKENS']) && (
            <ListItemLink
              id="app-parts-header-tokens"
              key="app-parts-header-tokens"
              primary={t('resourceMenu.hardwareTokens')}
              to="/tokens"
            />
          ),

        // Resources -> Smart Cards
        //isAuthenticationAccount &&
        //  userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SMARTCREDENTIALS']) && (
        //    <ListItemLink
        //      id="app-parts-header-smart-cards"
        //      key="app-parts-header-smart-cards"
        //      primary={t('resourceMenu.smartCards')}
        //      to="/smartcards"
        //    />
        //  ),

        // Resources -> Certificate Authorities
        isAuthenticationAccount &&
          smartLoginEnabled &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['CAS']) && (
            <ListItemLink
              id="app-parts-header-ca"
              key="app-parts-header-ca"
              primary={t('resourceMenu.certificateAuth')}
              to="/cas"
            />
          ),

        // Resources -> Smart Credential Definitions
        isAuthenticationAccount &&
          smartLoginEnabled &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SCDEFNS']) && (
            <ListItemLink
              id="app-parts-header-sc"
              key="app-parts-header-sc"
              primary={t('resourceMenu.smartCredDef')}
              to="/sc"
            />
          ),
        // Resources -> Domain Controller Certificates
        isAuthenticationAccount &&
          smartLoginEnabled &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['DOMAINCONTROLLERCERTS']) && (
            <ListItemLink
              id="app-parts-header-dccerts"
              key="app-parts-header-dccerts"
              primary={t('resourceMenu.dcCertsDef')}
              to="/dccerts"
            />
          ),
        isAuthenticationAccount &&
          isOIDCEnabled &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['VCDEFNS']) && (
            <ListItemLink
              id="app-parts-header-verifiableCredentialDefinitions"
              key="app-parts-header-verifiableCredentialDefinitions"
              primary={t('resourceMenu.verifiableCredentialDefinitions')}
              to="/credentialdefinitions"
            />
          ),
        isAuthenticationAccount &&
          isOIDCEnabled &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['VPDEFNS']) && (
            <ListItemLink
              id="app-parts-header-verifiablePresentationDefinitions"
              key="app-parts-header-verifiablePresentationDefinitions"
              primary={t('resourceMenu.verifiablePresentationDefinitions')}
              to="/presentationdefinitions"
            />
          ),
        // Resources -> Grid Cards
        isAuthenticationAccount &&
          userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['GRIDS']) && (
            <ListItemLink
              id="app-parts-header-grids"
              key="app-parts-header-grids"
              primary={t('resourceMenu.gridCards')}
              to="/grids"
            />
          ),

        // Resources -> Printers
        printMenu,

        // Resources -> Credential Designs
        credentialDesignsMenu,

        // Resources -> Workflow Designs
        workflowDesignsMenu,

        // Resources -> Credentials
        credentialsMenu,

        // Resources -> Mobile Flash Pass Designs
        mobileFlashPassDesignsMenu,

        //Resources -> Print Queue
        printQueue,

        // Resources -> Webhooks
        webhooks
      ]}
    />
  );
};

const GetConfigurationMenuItems = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const isIssuanceBundle = useSelector((state            ) => state.settings.bundles.hasIssuance);
  const permissions = useSelector((state            ) => state.permissions);
  const bundleFeatures = useSelector((state            ) => getHighestRankedBundle(state));
  const isFleetOnlyTenant = useSelector((state            ) => isFleetOnlyIssuanceTenant(state.settings, state.themes));
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);
  const isBasicSupportRole = useSelector((state            ) =>
    isRole(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR, SITE_ISSUANCE_BASIC_SUPPORT_ROLE_ID)
  );
  const isEnhancedSupportRole = useSelector((state            ) =>
    isRole(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR, SITE_ISSUANCE_ENHANCED_SUPPORT_ROLE_ID)
  );

  const isRiskEngineEnabled = filterByBundleFeatureName('externalRiskEngine', bundleFeatures)?.enabled;

  // Passthrough is supported using Auth API applications, so using Auth API feature
  const passthroughEnabled = filterByBundleFeatureName('authApi', bundleFeatures)?.enabled;

  // If the user has none of these permissions then disable the Configuration menu item
  if (
    !userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SETTINGS']) ||
    isBasicSupportRole ||
    isEnhancedSupportRole
  ) {
    return null;
  }

  return (
    <NestedListItem
      id="app-parts-header-configurations"
      icon={isAuthenticationAccount ? <SettingsIcon /> : null}
      primary={t('SettingsMenu.configuration')}
      nestedItems={[
        // Configuration -> KBA
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-kba"
            key="app-parts-header-kba"
            inset2={!isAuthenticationAccount}
            primary={t('breadCrumbs.kbaAuth')}
            to="/settings/kba"
          />
        ),

        // Configuration -> Transactions
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-transactions"
            key="app-parts-header-transactions"
            inset2={!isAuthenticationAccount}
            primary={t('breadCrumbs.transaction')}
            to="/settings/transactions"
          />
        ),

        // Configuration -> External Risk Engine
        isAuthenticationAccount && isRiskEngineEnabled && (
          <ListItemLink
            id="app-parts-header-riskEngines"
            key="app-parts-header-riskEngines"
            primary={t('breadCrumbs.riskEngines.title')}
            to="/settings/riskengines"
          />
        ),

        // Configuration -> Passthrough Authenticator Definitions
        isAuthenticationAccount && passthroughEnabled && (
          <ListItemLink
            id="app-parts-header-passthrough"
            key="app-parts-header-passthrough"
            primary={t('breadCrumbs.passthrough.title')}
            to="/settings/passthrough"
          />
        ),

        // Configuration -> CORS
        <ListItemLink
          id="app-parts-header-cors"
          key="app-parts-header-cors"
          inset2={!isAuthenticationAccount}
          primary={t('breadCrumbs.cors')}
          to="/settings/cors"
        />,

        // Configuration -> Password Blocklist
        <ListItemLink
          id="app-parts-header-passwordBlockList"
          key="app-parts-header-passwordBlockList"
          inset2={!isAuthenticationAccount}
          primary={t('breadCrumbs.blocklist')}
          to="/settings/blocklist"
        />,
        // Configuration -> Email
        !isFleetOnlyTenant && (
          <ListItemLink
            id="app-parts-header-email"
            key="app-parts-header-email"
            inset2={!isAuthenticationAccount}
            primary={t('SettingsMenu.mailServer')}
            to="/settings/email"
          />
        ),

        // Configuration -> Device Fingerprinting
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-devices"
            key="app-parts-header-devices"
            primary={t('SettingsMenu.deviceFingerPrinting')}
            to="/settings/devices"
          />
        ),

        // Configuration -> SDP
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-sdp"
            key="app-parts-header-sdp"
            primary={t('breadCrumbs.sdp')}
            to="/settings/activesyncaccess"
          />
        ),

        // Configuration -> Mobile Flash Pass
        !isAuthenticationAccount && isIssuanceBundle && !isFleetOnlyTenant && !isDirectTenant && (
          <ListItemLink
            id="app-parts-header-mobileflashpass"
            key="app-parts-header-mobileflashpass"
            disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SETTINGS'])}
            inset2={!isAuthenticationAccount}
            primary={t('breadCrumbs.mobileFlashPassSettings')}
            to="/settings/mobileflashpass"
          />
        ),

        // Configuration -> Notifications
        <ListItemLink
          id="app-parts-header-notification"
          key="app-parts-header-notification"
          inset2={!isAuthenticationAccount}
          primary={t('breadCrumbs.notification')}
          to="/settings/notification"
        />,
        !isFleetOnlyTenant && (
          // Configuration -> IP Lists
          <ListItemLink
            id="app-parts-header-iplists"
            key="app-parts-header-iplists"
            inset2={!isAuthenticationAccount}
            primary={t('breadCrumbs.ipLists')}
            to="/settings/iplists"
          />
        ),
        // Configuration -> Face Account
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-onfido"
            key="app-parts-header-onfido"
            primary={t('breadCrumbs.onfidoAccount')}
            to="/settings/onfido"
          />
        )
      ]}
    />
  );
};

const GetSettingsMenuItems = () => {
  const currentAdminRole = useSelector((state            ) =>
    getRoleByType(state.permissions, ROLE_TYPE_SITE_ADMINISTRATOR)
  );
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);

  // If the user has none of these permissions then disable the Settings menu item
  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SETTINGS'])) {
    return null;
  }

  const icon = isAuthenticationAccount ? <GroupPolicyIcon /> : null;

  return (
    <NestedListItem
      id="app-parts-header-settings"
      icon={icon}
      primary={t('SettingsMenu.policies')}
      nestedItems={[
        // Policies -> General
        <ListItemLink
          id="app-parts-header-general"
          key="app-parts-header-general"
          inset2={!isAuthenticationAccount}
          primary={t('SettingsMenu.general')}
          to="/settings/general"
        />,

        // Policies -> Registration
        <ListItemLink
          id="app-parts-header-registration"
          key="app-parts-header-registration"
          inset2={!isAuthenticationAccount}
          primary={t('SettingsMenu.registration')}
          to="/settings/registration"
        />,

        // Policies -> Authenticators
        <ListItemLink
          id="app-parts-header-authenticators"
          key="app-parts-header-authenticators"
          inset2={!isAuthenticationAccount}
          primary={t('SettingsMenu.authenticators')}
          to="/settings/authenticators"
        />,

        // Policies -> Risk-Based Authentication
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-rba"
            key="app-parts-header-rba"
            primary={t('breadCrumbs.riskAuth')}
            to="/settings/rba"
          />
        ),
        //Policies -> Soft Token SDK
        isAuthenticationAccount && (
          <ListItemLink
            id="app-parts-header-softTokenSDK"
            key="app-parts-header-softTokenSDK"
            primary={t('breadCrumbs.softTokenSDK')}
            to="/settings/softTokenSDK"
          />
        ),
        //Policies -> User Notifications
        <ListItemLink
          id="app-parts-header-user-contact-notification"
          key="app-parts-header-user-contact-notification"
          inset2={!isAuthenticationAccount}
          primary={t('breadCrumbs.userNotification')}
          to="/settings/UserNotification"
        />,
        // Policies -> User Portal
        <ListItemLink
          id="app-parts-header-user-portal"
          key="app-parts-header-user-portal"
          inset2={!isAuthenticationAccount}
          primary={t('SettingsMenu.userPortal')}
          to="/settings/userportal"
        />,
        // Policies -> Group Policy
        isAuthenticationAccount && currentAdminRole.groupManagement === 'ALL' && (
          <ListItemLink
            id="app-parts-header-groupPolicies"
            key="app-parts-header-groupPolicies"
            inset2={!isAuthenticationAccount}
            primary={t('breadCrumbs.groupPolicies')}
            to="/settings/grouppolicies"
          />
        )
      ]}
    />
  );
};

const GetIssuanceReportMenu = () => {
  const isSPAdmin = useSelector((state            ) => userHasRole(state.permissions, ROLE_TYPE_ACCOUNT_MANAGER));
  const permissions = useSelector((state            ) => state.permissions);

  const menu = [];

  const report = userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, [
    'EXPORTREPORTS',
    'ARCHIVES'
  ]) ? (
    <GetReportsMenuItems key="app-parts-header-export-reports" />
  ) : null;

  if (report) {
    menu.push(report);
  }

  const scheduled = getScheduledTasksMenuItems(isSPAdmin, false, permissions);
  if (scheduled) {
    menu.push(scheduled);
  }

  if (menu.length === 0) {
    return null;
  }

  return (
    <NestedListItem
      id="app-parts-header-issuance-report"
      icon={null}
      primary={t('breadCrumbs.reports')}
      nestedItems={menu}
    />
  );
};

const GetCustomizationMenuItems = () => {
  const isAuthenticationAccount = useSelector((state            ) => state.themes.subscriber.authenticationAccount);
  const permissions = useSelector((state            ) => state.permissions);
  const isDirectTenant = useSelector((state            ) => state.settings.bundles.directTenant);
  const isDirectTenantLocked = useSelector((state            ) => state.settings.bundles.directTenantLocked);

  // If the user has none of these permissions then disable the Customization menu item
  if (!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SUBSCRIBERS', 'EMAILTEMPLATES'])) {
    return null;
  }

  const icon = isAuthenticationAccount ? <WidgetsIcon /> : null;

  return (
    <NestedListItem
      id="app-parts-header-customize"
      icon={icon}
      primary={t('CustomizationMenu.customization')}
      nestedItems={[
        // Customization -> Theme
        <ListItemLink
          id="app-parts-header-theme"
          key="app-parts-header-theme"
          disabled={
            !userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['SUBSCRIBERS']) ||
            (isDirectTenant && isDirectTenantLocked)
          }
          inset2={!isAuthenticationAccount}
          primary={t('CustomizationMenu.theme')}
          to="/theme"
        />,
        // Customization -> Email Templates
        <ListItemLink
          id="app-parts-header-emailTemplate"
          key="app-parts-header-emailTemplate"
          disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_SITE_ADMINISTRATOR, ['EMAILTEMPLATES'])}
          inset2={!isAuthenticationAccount}
          primary={t('CustomizationMenu.emailTemplates')}
          to="/emailTemplates"
        />
      ]}
    />
  );
};

/**
 * Function that retrieves the menu items for the Service Provider Portal
 * @param  {Boolean} isSPAdmin       Whether or not the user is an ACCOUNT_MANAGER
 * @param  {Boolean} isRootSPAccount Whether or not the account is the root Service Provider account
 * @param  {[type]}  permissions     Redux variable containing all of the user's permissions
 * @return {[type]}                  Returns an array of menu items.
 */
const getServiceProviderPortalMenuItems = (
  isSPAdmin         ,
  isRootSPAccount         ,
  permissions                 
) => {
  if (!isSPAdmin) {
    return [];
  }

  const menuItems = [];

  // Service Provider

  menuItems.push(
    // Service Provider
    <NestedListItem
      id="app-parts-header-sp"
      key="app-parts-header-sp"
      icon={<SupervisorIcon />}
      primary={t('ServiceProviderMenu.serviceProvider')}
      nestedItems={[
        // Service Provider -> Tenants
        <ListItemLink
          id="app-parts-header-tenants"
          key="app-parts-header-tenants"
          disabled={
            !userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['TENANTS']) ||
            isRole(permissions, ROLE_TYPE_ACCOUNT_MANAGER, SERVICE_PROVIDER_API_ACCOUNT_ONBOARDING_ROLE_ID)
          }
          primary={t('ServiceProviderMenu.tenants')}
          to={'/serviceprovider/tenants'}
        />,

        // Service Provider -> OTP Providers
        isRootSPAccount ? (
          <ListItemLink
            id="app-parts-header-otpproviders"
            key="app-parts-header-otpproviders"
            disabled={
              !userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['OTPPROVIDERS']) ||
              isRole(permissions, ROLE_TYPE_ACCOUNT_MANAGER, SERVICE_PROVIDER_API_ACCOUNT_ONBOARDING_ROLE_ID)
            }
            primary={t('ServiceProviderMenu.otpProviders')}
            to={'/serviceprovider/otpproviders'}
          />
        ) : null,

        // Service Provider -> Client Credentials
        isRootSPAccount ? (
          <ListItemLink
            id="app-parts-header-clientCredentials"
            key="app-parts-header-clientCredentials"
            disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['SPCLIENTCREDENTIALS'])}
            primary={t('ServiceProviderMenu.clientCredential')}
            to={'/serviceprovider/spclientcredentials'}
          />
        ) : null,

        // Service Provider -> Management Platform
        isRootSPAccount ? (
          <ListItemLink
            id="app-parts-header-managementPlatform"
            key="app-parts-header-managementPlatform"
            disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['SPMANAGEMENTPLATFORM'])}
            primary={t('ServiceProviderMenu.managementPlatform')}
            to="/serviceprovider/managementplatform"
          />
        ) : null,

        // Service Provider -> PKIaaS Credentials
        isRootSPAccount ? (
          <ListItemLink
            id="app-parts-header-pkiaasCredentials"
            key="app-parts-header-pkiaasCredentials"
            disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['PKIAASCREDENTIALS'])}
            primary={t('ServiceProviderMenu.pkiaasCredentials')}
            to="/serviceprovider/pkiaascredentials"
          />
        ) : null,

        // Service Provider -> Push Credentials
        isRootSPAccount ? (
          <ListItemLink
            id="app-parts-header-pushCredentials"
            key="app-parts-header-pushCredentials"
            disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['PUSHCREDENTIALS'])}
            primary={t('ServiceProviderMenu.pushCredentials')}
            to="/serviceprovider/pushcredentials"
          />
        ) : null,

        // Service Provider -> Rate Limiting
        isRootSPAccount ? (
          <ListItemLink
            id="app-parts-header-rateLimiting"
            key="app-parts-header-rateLimiting"
            disabled={!userHasPermissionsForRoleType(permissions, ROLE_TYPE_ACCOUNT_MANAGER, ['RATELIMITING'])}
            primary={t('ServiceProviderMenu.rateLimiting')}
            to="/serviceprovider/ratelimiting"
          />
        ) : null
      ]}
    />
  );

  return menuItems;
};

                          
                    
                    
             
                 
                      
            
  

const useStyles = makeStyles(theme => ({
  active: {
    color: `${theme.palette.text.link} !important`,
    backgroundColor: alpha(theme.palette.text.link, 0.1)
  },
  regular: {
    color: theme.palette.text.primary
  },
  inset: {
    marginLeft: theme.spacing(3)
  },
  inset2: {
    marginLeft: theme.spacing(9)
  }
}));

const ListItemLink = (props                   ) => {
  const { disabled, icon, id, inset, inset2, primary, to } = props;

  const classes = useStyles();

  const renderLink = React.useMemo(() => {
    const RefLink = (itemProps, ref) => <NavLink to={to} {...itemProps} ref={ref} activeClassName={classes.active} />;

    return React.forwardRef(RefLink);
  }, [classes.active, to]);

  const insetClass = inset2 ? classes.inset2 : classes.inset;
  return (
    <ListItem id={id} className={classes.regular} button component={renderLink} disabled={disabled}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText className={clsx({ [insetClass]: inset })} primary={primary} />
    </ListItem>
  );
};

ListItemLink.defaultProps = {
  disabled: false,
  inset: true,
  inset2: false
};
