import ADCONNECTORAPI from 'src/assets/images/lgchick/ADCONNECTORAPI.svg';
import ADFS from 'src/assets/images/lgchick/IDG-ADFS.svg';
import ADMINAPI from 'src/assets/images/lgchick/ADMINAPI.svg';
import ADP from 'src/assets/images/lgchick/ADP.svg';
import AKAMAI from 'src/assets/images/lgchick/Akamai.svg';
import ONFIDO from 'src/assets/images/lgchick/Onfido.svg';
import SAML from 'src/assets/images/lgchick/SAML.svg';
import APACHE from 'src/assets/images/lgchick/APACHE.svg';
import APPDYNAMICS from 'src/assets/images/lgchick/AppDynamics.svg';
import ASANA from 'src/assets/images/lgchick/Asana.svg';
import ATLASSIAN_ACCESS from 'src/assets/images/lgchick/Atlassian.svg';
import AUTHAPI from 'src/assets/images/lgchick/AUTHAPI.svg';
import AWARDCO from 'src/assets/images/lgchick/Awardco.svg';
import AWS from 'src/assets/images/lgchick/AWS.svg';
import AZURE_AD from 'src/assets/images/lgchick/AzureAD.svg';
import BAMBOOHR from 'src/assets/images/lgchick/BambooHR.svg';
import BARRACUDA from 'src/assets/images/lgchick/Barracuda.svg';
import BOOMI from 'src/assets/images/lgchick/Boomi.svg';
import BOX from 'src/assets/images/lgchick/Box.svg';
import CHECKPOINT from 'src/assets/images/lgchick/Checkpoint.svg';
import CISCO from 'src/assets/images/lgchick/Cisco.svg';
import CITRIXREADY from 'src/assets/images/lgchick/CitrixReady.svg';
import OPENIDCONNECTSPA from 'src/assets/images/lgchick/OpenIdConnectSpa.svg';
import COUPA from 'src/assets/images/lgchick/Coupa.svg';
import CUSTOMIDG from 'src/assets/images/lgchick/IDG-Custom.svg';
import DATADOG from 'src/assets/images/lgchick/Datadog.svg';
import DOCUSIGN from 'src/assets/images/lgchick/DocuSign.svg';
import DROPBOX from 'src/assets/images/lgchick/Dropbox.svg';
import ELOQUA from 'src/assets/images/lgchick/Oracle-Eloqua.svg';
import ENVOY from 'src/assets/images/lgchick/Envoy.svg';
import EVERNOTE from 'src/assets/images/lgchick/Evernote.svg';
import EXPENSIFY from 'src/assets/images/lgchick/Expensify.svg';
import F5 from 'src/assets/images/lgchick/F5.svg';
import FORGEROCK from 'src/assets/images/lgchick/ForgeRock.svg';
import FORTINET from 'src/assets/images/lgchick/Fortinet.svg';
import GITHUB from 'src/assets/images/lgchick/GitHub.svg';
import GOOGLE from 'src/assets/images/lgchick/GoogleWorkspace.svg';
import HOOTSUITE from 'src/assets/images/lgchick/Hootsuite.svg';
import IDAAS_ADFS from 'src/assets/images/lgchick/Entrust-ADFS.svg';
import IDAAS_DESKTOP from 'src/assets/images/lgchick/Entrust-Desktop.svg';
import IDAAS_ISAPI from 'src/assets/images/lgchick/Entrust-ISAPI.svg';
import IDG_DESKTOP from 'src/assets/images/lgchick/IDG-Desktop.svg';
import ISAPI from 'src/assets/images/lgchick/IDG-ISAPI.svg';
import ISSUANCEAPI from 'src/assets/images/lgchick/ISSUANCEAPI.svg';
import JAMF from 'src/assets/images/lgchick/Jamf.svg';
import JENKINS from 'src/assets/images/lgchick/Jenkins.svg';
import KNOWBE4 from 'src/assets/images/lgchick/KnowBe4.svg';
import LOGMEIN from 'src/assets/images/lgchick/LogMeIn.svg';
import MICROSOFT_EAM from 'src/assets/images/lgchick/MicrosoftEntraID.svg';
import MIMECAST from 'src/assets/images/lgchick/Mimecast.svg';
import MONDAYDOTCOM from 'src/assets/images/lgchick/Monday.svg';
import MULESOFT from 'src/assets/images/lgchick/MuleSoft.svg';
import NETMOTION from 'src/assets/images/lgchick/NetMotion.svg';
import NETSCALER from 'src/assets/images/lgchick/NetScaler.svg';
import NEWRELIC from 'src/assets/images/lgchick/NewRelic.svg';
import OFFICE365 from 'src/assets/images/lgchick/Office365.svg';
import OPENIDCONNECTDEVICE from 'src/assets/images/lgchick/OpenIdConnectDevice.svg';
import OPENIDCONNECTNATIVE from 'src/assets/images/lgchick/OpenIdConnectNative.svg';
import OPENIDCONNECTSERVER from 'src/assets/images/lgchick/OpenIdConnectServer.svg';
import OPENIDCONNECTWEB from 'src/assets/images/lgchick/OpenIdConnectWeb.svg';
import RADIUS from 'src/assets/images/lgchick/Radius.svg';
import ORACLEEPM from 'src/assets/images/lgchick/OracleEPM.svg';
import PAGERDUTY from 'src/assets/images/lgchick/PagerDuty.svg';
import PALOALTO from 'src/assets/images/lgchick/Palo-Alto.svg';
import PULSESECURE from 'src/assets/images/lgchick/PulseSecure.svg';
import REVIEWINC from 'src/assets/images/lgchick/ReviewInc.svg';
import RINGCENTRAL from 'src/assets/images/lgchick/RingCentral.svg';
import SAILPOINT from 'src/assets/images/lgchick/SailPoint.svg';
import SALESFORCE from 'src/assets/images/lgchick/Salesforce.svg';
import SERVICENOW from 'src/assets/images/lgchick/ServiceNow.svg';
import SHIELD_ACCOUNT from 'src/assets/images/lgchick/ShieldAccount.svg';
import SHOPIFYPLUS from 'src/assets/images/lgchick/ShopifyPlus.svg';
import SIEMSYSLOG from 'src/assets/images/lgchick/SIEMSyslog.svg';
import SLACK from 'src/assets/images/lgchick/Slack.svg';
import SMARTSHEET from 'src/assets/images/lgchick/Smartsheet.svg';
import SNOWFLAKE from 'src/assets/images/lgchick/Snowflake.svg';
import SONICWALL from 'src/assets/images/lgchick/SonicWall.svg';
import SOPHOS from 'src/assets/images/lgchick/Sophos.svg';
import SPLUNK from 'src/assets/images/lgchick/Splunk.svg';
import SUMOLOGIC from 'src/assets/images/lgchick/SumoLogic.svg';
import TABLEAU from 'src/assets/images/lgchick/Tableau.svg';
import VMWARE from 'src/assets/images/lgchick/VMware.svg';
import WEBEX from 'src/assets/images/lgchick/Webex.svg';
import WHITESOURCE from 'src/assets/images/lgchick/Whitesource.svg';
import WORKDAY from 'src/assets/images/lgchick/Workday.svg';
import WORKFRONT from 'src/assets/images/lgchick/Workfront.svg';
import ZENDESK from 'src/assets/images/lgchick/Zendesk.svg';
import ZOHOONE from 'src/assets/images/lgchick/ZohoOne.svg';
import ZOOM from 'src/assets/images/lgchick/Zoom.svg';
import ZSCALER from 'src/assets/images/lgchick/Zscaler.svg';
import ZUORA from 'src/assets/images/lgchick/Zuora.svg';

export const ALL_GROUP_ID = '7c0b758a-b068-4a21-9f2c-9227fb31a1ea';

export const CURRENT_PASSWORD_RESET_VERSION = 2;

export const APP_LOGOS = Object.freeze({
  'e936cbd8-5f5f-4057-9ee7-41a5a3e792d8': ADCONNECTORAPI,
  'f307addb-af1a-455b-896e-d26e8da8e3fb': ADFS,
  '00ef4f64-j541-9854-sdde-asd34hgnb671': ADMINAPI,
  '27e53a61-7ccc-4eb3-846e-95857ab3ac7d': ADP,
  'a152c56b-f1db-4c1e-9e23-6ecd39c1a79b': SAML,
  '4104a9a2-d229-4fa0-9e1b-6816da70a5ac': AKAMAI,
  'b992b0d1-e1c7-42f2-846e-7bc322582303': SAML,
  '1429b438-167a-44bd-ab7f-3d0da07a67dd': SAML,
  'e32f7bdd-f6b8-44fb-822c-31ada767c63d': APACHE,
  '4647c7b0-189d-405c-aac5-734e758c7f88': APPDYNAMICS,
  '00dc1d40-b512-4159-bccc-e8c1b4524515': AUTHAPI,
  '2ad1977b-d8d7-41e8-8bb2-0096b95cac59': ASANA,
  '4ae0435a-8de6-4695-b1ac-54ba72908403': ATLASSIAN_ACCESS,
  '52f61126-6acc-478b-b2e7-f0c41628c8bd': AWARDCO,
  '6d28287d-baf8-4aca-a1da-9ece47515212': AWS,
  '2a4b9eb2-9e80-4e61-8e53-729daeadbf9d': AZURE_AD,
  '380cacfa-45d8-4b9b-a25e-56d93f47f975': BAMBOOHR,
  'd89437de-1466-4588-9060-424b7ad75d0a': BARRACUDA,
  'a138c98b-29fe-4dd4-aad5-0682786797ec': BOX,
  'ad7982ab-445a-4bce-92ef-5263cc015a8d': SAML, // BONUSLY
  'd27249e2-98f9-420d-8d30-971e844d6b87': BOOMI,
  'eab67731-0879-4f9c-984d-e7fc3695062c': CHECKPOINT,
  'bc166e0a-db54-11e7-9296-cec278b6b50a': CISCO,
  '455d77fd-37bd-47fc-a9f2-21400b1c0c6b': CISCO,
  'e7f21411-006d-4ec9-90c8-2e5fd8ce0c1e': CITRIXREADY,
  'e5353e7e-beb3-408a-be77-82f9696b633a': SAML,
  '94254d32-568f-4329-9480-2dbbdfdda43e': OPENIDCONNECTSPA,
  '915d58d3-f1a1-4f97-910d-17fb9bdf4722': SAML,
  'cbfac30d-32e9-421b-afd9-b434982cbc6b': COUPA,
  '90eb4924-9590-4cc3-88c7-2942f2404ad8': CUSTOMIDG,
  'f75a37cd-72b5-4796-8f39-99a19b0251db': DATADOG,
  '43fbe82d-abc2-43bf-825d-4aaeaf96086e': DOCUSIGN,
  '55b5def1-8aba-4e1a-a47c-cb5a97e2201b': DROPBOX,
  '043517a6-414a-4744-844f-cff2c922d96c': SAML,
  '1848648f-00ef-440b-ba22-f39746083f0b': ELOQUA,
  '74731778-5deb-4ec2-a63d-1040482081b7': ENVOY,
  '4efdf1cc-7952-4100-8ba9-02e9c94754dd': SAML,
  '6c8fc655-d8d1-41b0-ae30-f37d1b95f947': EVERNOTE,
  '330ba006-cef3-4ed4-a3cf-e981918f18ea': EXPENSIFY,
  '3e36e16d-c699-4f94-a2e2-52fa427ef5d5': F5,
  'e1e0c183-a330-489b-adf0-57f45d925d21': SAML,
  '8a467609-9d99-4c64-a9dd-e21e7917bb81': SAML,
  'a72d9af5-4940-48a7-84f4-4c3032313109': SAML,
  '637a723b-f311-47c7-a238-a52c2036d8aa': SAML,
  '3fe22edf-0dcd-4dd8-86a1-9ce0c2d7d50b': FORGEROCK,
  '2fa6e185-596d-4096-b48c-99f569b46156': FORGEROCK,
  '5571326c-c53e-4a9b-9e80-d6250ea53e00': FORGEROCK,
  '36103a6c-67c3-4f9e-8592-d664ef6c480b': FORTINET,
  'be9f13d8-47b7-4526-8e9f-8269a2e8e6d1': FORTINET,
  'd222adfc-3687-49e5-a985-d6c0dbaf65e9': SAML,
  '0471908e-43b7-455f-bb53-f31b17c6056f': SAML,
  '7c6a764a-7854-4d39-9405-c78c317a6bc7': OPENIDCONNECTWEB,
  'dfe1cc33-b6e4-40fa-ad1c-a97455b84eca': GITHUB,
  '628f03b7-d431-4289-be71-9b37302665b1': SAML,
  '2de21ef8-855f-4b53-b70f-a286a401bbe9': GOOGLE,
  '5cbe14b8-f55e-4adb-9d43-0d64182c5bfa': HOOTSUITE,
  '5316d104-8027-4156-a379-5c3dee09262e': SAML,
  '1a3e91ed-fec2-4635-ad46-4a922b2707eb': SAML,
  '00cb9c20-a401-4048-abbb-d7b9a3418349': IDG_DESKTOP,
  '7759eb80-7b89-11e9-8f9e-2a86e4085a59': IDAAS_ADFS,
  'dbbe32a8-8065-11e9-bc42-526af7764f64': IDAAS_DESKTOP,
  'ac8e7051-e7c6-4a6f-92f2-cf30f107f54f': IDAAS_ISAPI,
  '4ae110db-e996-45c2-ae12-6f17dd9a6a85': ISAPI,
  'bb68860d-21ce-4756-a5a4-ddfa33bb35cc': ISSUANCEAPI,
  '9887ca61-11cc-471d-aef9-b5d2afae4bf5': JAMF,
  'efd7917a-be47-464f-9e53-46546e07016c': JENKINS,
  '9e789ee6-15cd-4e67-856b-2c2fbd0e3160': SAML,
  '750fbe66-d2e4-4698-8666-90bf8b2175e8': KNOWBE4,
  '4692f072-705c-4c91-bf8d-67410104c53e': SAML,
  'b03b1e5a-9339-48cf-b8b3-5666253bdc16': LOGMEIN,
  '6f86daa5-eaa6-4916-bf34-9ded0fe5e984': SAML,
  'a652d057-0a5a-46ed-b911-f878f994f7d7': MICROSOFT_EAM,
  'aa9760ae-0d15-4592-a374-acf7837c41e5': MIMECAST,
  '1d123c98-6b95-4a43-86a2-a596e26f02d5': SAML,
  'c11911bc-6cd1-496e-be3e-b04c7e4dc611': MONDAYDOTCOM,
  '3574887c-fbc4-4ece-a8ab-cca2ee1f95b4': MULESOFT,
  '505fdbb8-af45-4dce-9a6a-a22071300b92': NETMOTION,
  'da38d1f6-e58c-11e7-80c1-9a214cf093ae': NETSCALER,
  '333fc933-9d80-4521-81e2-3b9642055f85': SAML,
  'ac7e6e4c-6696-4893-9b6a-e635f6e248a6': NEWRELIC,
  '111fcc42-10d2-45b7-9ee5-86631ab5e966': OFFICE365,
  '8465be2a-e4aa-4c06-a1fe-f4bba4c36ede': ONFIDO,
  '91f777d4-0dd1-4844-966c-d42ab3a58fa7': OPENIDCONNECTWEB,
  '12e2b5ee-2e1e-4237-b77d-c5f7a9395fb9': OPENIDCONNECTSPA,
  '5dbf879e-a304-4126-9abb-f6cce5b6c86e': OPENIDCONNECTSERVER,
  '3fe486ba-81f9-4ddf-8c8d-bf7572177b1f': OPENIDCONNECTNATIVE,
  'f9f748fe-0a52-4ad5-9ec5-2f667062b644': OPENIDCONNECTDEVICE,
  '32086c2d-d7ba-483e-b475-3536bf930b24': OPENIDCONNECTNATIVE,
  '97ff8475-abaa-4413-83a5-e51c3035207c': SAML, // PRODPAD
  'e25ff9c4-2cf9-4750-95aa-ecd01a1e8e50': RADIUS,
  '841fbd57-6ebc-4ea7-ae93-7a538b6fbf6c': ORACLEEPM,
  'dec78c2f-e5ce-466b-be76-404c0c202e27': PAGERDUTY,
  '7e60a4be-d3f7-4a4f-8f17-841c9aea1a8f': PALOALTO,
  '34e601dc-b18e-45af-aa8e-c85d5c18cef7': PALOALTO,
  'a7d1ddaf-eb68-4cbc-9f1d-0ff4e6f4e9ea': RADIUS,
  '7f1ad58b-6d3b-45c0-bba0-7c5a7bbd2acd': SAML,
  '0af5dbc9-d14b-4d9f-9253-e2c853ba11ea': PULSESECURE,
  '55e51625-05cd-4074-9371-3244d67e3920': RADIUS,
  '79f0c757-4c9a-4fb7-b257-129373e90af8': REVIEWINC,
  '63414c3d-19d3-48cf-b63d-32eca8376099': RINGCENTRAL,
  '74b0d73d-36dd-4c48-b526-5e2ae0c1fa99': SAILPOINT,
  'ca32eee6-3274-4b28-9ec3-8c6c75a7c6e1': SALESFORCE,
  '90a22a2b-ecba-4def-a5e7-89e8d9d46c48': SALESFORCE,
  '76132cda-3c42-4ed3-8d02-8d062368c472': SAML,
  '264e145a-3ee9-4fd4-9934-bee0e91e581c': SERVICENOW,
  'ccbe31c8-730b-4d2d-87be-2abc066646ca': SAML,
  'c37c29fe-9173-4a37-ba9f-7514cca672e9': SIEMSYSLOG,
  '4a129d88-d59b-4bff-9ce2-a7d247415a43': SLACK,
  '53462a46-d045-4269-815b-239125835a3c': SAML,
  '746a40bf-b67a-4b75-9a0a-bcbaf09ca4ab': SONICWALL,
  'c09dd636-4615-4ee2-b586-abeda594c4d6': SOPHOS,
  '7593dfb5-dd90-49b2-9dd8-b69b28d36f27': SHIELD_ACCOUNT,
  '6116cdc9-08e8-4450-a948-2ff62b4ea2ad': SHIELD_ACCOUNT,
  'd6e12e3e-8603-47f1-b855-8b5012d6ba4a': SHOPIFYPLUS,
  '092e48c5-88ec-4182-bf0c-4f85e28a339b': SAML,
  '2e18632f-7a2f-4897-9a90-5102af93f971': SMARTSHEET,
  '4bf39193-42a8-485b-ab50-3991fb3f854f': SNOWFLAKE,
  '003d47dd-4183-4caa-a1a9-5e5f9218e991': OPENIDCONNECTWEB,
  '10f2aecb-1703-45d9-94e5-d27aa6a393a9': SPLUNK,
  '02eb330c-6d06-4451-8b7f-db8b65d1d6cf': SPLUNK,
  '53f4d92c-62d7-4b28-b846-43c110dcdf68': SAML,
  '0b8192df-5ca9-4ab5-9854-de0c760ece41': SUMOLOGIC,
  '49db351d-a5dc-4e8c-ae90-190d19f8198d': TABLEAU,
  'c8410c04-1976-4317-846a-1585082bd0ed': VMWARE,
  'c92d44af-1767-43f3-a455-db3ebea821fb': WEBEX,
  '4f005adc-9151-47a8-af1b-fd74d6772b9e': WHITESOURCE,
  '7082ea5c-6ec5-4af5-be3d-4f0e837f1348': WORKDAY,
  '288fffa2-683c-4e3d-b456-d812cd0c8eb0': WORKFRONT,
  'd402141d-16db-4b74-8c45-7326e692d187': ZENDESK,
  '3fdfe0dc-ea27-4d27-bb0e-c3c47e3c68bb': SAML,
  '625bd8dd-f57d-4b3f-a2b4-8c860f3fd5cf': ZOHOONE,
  '6f4f6d28-0fd5-416e-99c1-2566c3902d3d': ZOOM,
  '112377f6-270d-4d52-8a09-9bc99ed0c21c': ZSCALER,
  '106b81e1-5c5d-47a6-97ca-96fd991ed343': ZUORA,
  '2d3040b7-9642-4e1f-bc5b-a5ce39f191b0': SHIELD_ACCOUNT
});

export const APPS = Object.freeze({
  ADCONNECTORAPI: 'e936cbd8-5f5f-4057-9ee7-41a5a3e792d8',
  ADMIN_PORTAL: '6116cdc9-08e8-4450-a948-2ff62b4ea2ad',
  ADMINAPI: '00ef4f64-j541-9854-sdde-asd34hgnb671',
  ADP: '27e53a61-7ccc-4eb3-846e-95857ab3ac7d',
  AIR: 'a152c56b-f1db-4c1e-9e23-6ecd39c1a79b',
  AKAMAI: '4104a9a2-d229-4fa0-9e1b-6816da70a5ac',
  ALIBABA: 'b992b0d1-e1c7-42f2-846e-7bc322582303',
  AMAZON_BUSINESS: '1429b438-167a-44bd-ab7f-3d0da07a67dd',
  APACHE: 'e32f7bdd-f6b8-44fb-822c-31ada767c63d',
  APPDYNAMICS: '4647c7b0-189d-405c-aac5-734e758c7f88',
  ASANA: '2ad1977b-d8d7-41e8-8bb2-0096b95cac59',
  ATLASSIAN_ACCESS: '4ae0435a-8de6-4695-b1ac-54ba72908403',
  AUTHAPI: '00dc1d40-b512-4159-bccc-e8c1b4524515',
  AWARDCO: '52f61126-6acc-478b-b2e7-f0c41628c8bd',
  ADFS: 'f307addb-af1a-455b-896e-d26e8da8e3fb',
  AWS: '6d28287d-baf8-4aca-a1da-9ece47515212',
  AZURE_AD_CA: '2a4b9eb2-9e80-4e61-8e53-729daeadbf9d',
  BAMBOOHR: '380cacfa-45d8-4b9b-a25e-56d93f47f975',
  BARRACUDA: 'd89437de-1466-4588-9060-424b7ad75d0a',
  BONUSLY: 'ad7982ab-445a-4bce-92ef-5263cc015a8d',
  BOX: 'a138c98b-29fe-4dd4-aad5-0682786797ec',
  BOOMI: 'd27249e2-98f9-420d-8d30-971e844d6b87',
  CHECKPOINT: 'eab67731-0879-4f9c-984d-e7fc3695062c',
  CISCO: 'bc166e0a-db54-11e7-9296-cec278b6b50a',
  CISCO_ISE: '455d77fd-37bd-47fc-a9f2-21400b1c0c6b',
  CITRIXREADY: 'e7f21411-006d-4ec9-90c8-2e5fd8ce0c1e',
  CONFLUENT_CLOUD: 'e5353e7e-beb3-408a-be77-82f9696b633a',
  CONNECTWISE_OIDC: '94254d32-568f-4329-9480-2dbbdfdda43e',
  CONNECTWISE_SAML: '915d58d3-f1a1-4f97-910d-17fb9bdf4722',
  COUPA: 'cbfac30d-32e9-421b-afd9-b434982cbc6b',
  CUSTOMIDG: '90eb4924-9590-4cc3-88c7-2942f2404ad8',
  DATADOG: 'f75a37cd-72b5-4796-8f39-99a19b0251db',
  DOCUSIGN: '43fbe82d-abc2-43bf-825d-4aaeaf96086e',
  DROPBOX: '55b5def1-8aba-4e1a-a47c-cb5a97e2201b',
  DRUVA: '043517a6-414a-4744-844f-cff2c922d96c',
  ELOQUA: '1848648f-00ef-440b-ba22-f39746083f0b',
  ENVOY: '74731778-5deb-4ec2-a63d-1040482081b7',
  EPIC_HYPERDRIVE: '4efdf1cc-7952-4100-8ba9-02e9c94754dd',
  EVERNOTE: '6c8fc655-d8d1-41b0-ae30-f37d1b95f947',
  EXPENSIFY: '330ba006-cef3-4ed4-a3cf-e981918f18ea',
  F5: '3e36e16d-c699-4f94-a2e2-52fa427ef5d5',
  FASTLY: 'e1e0c183-a330-489b-adf0-57f45d925d21',
  FIVETRAN: 'a72d9af5-4940-48a7-84f4-4c3032313109',
  FORGEROCK: '3fe22edf-0dcd-4dd8-86a1-9ce0c2d7d50b',
  FORGEROCK_AM: '5571326c-c53e-4a9b-9e80-d6250ea53e00',
  FORTINET: '36103a6c-67c3-4f9e-8592-d664ef6c480b',
  FORTISIEM: 'be9f13d8-47b7-4526-8e9f-8269a2e8e6d1',
  GITHUB: 'dfe1cc33-b6e4-40fa-ad1c-a97455b84eca',
  GONG: '628f03b7-d431-4289-be71-9b37302665b1',
  GOOGLE: '2de21ef8-855f-4b53-b70f-a286a401bbe9',
  FIFTEENFIVE: '8a467609-9d99-4c64-a9dd-e21e7917bb81',
  FORESTADMIN: '637a723b-f311-47c7-a238-a52c2036d8aa',
  FRESHSERVICE: 'd222adfc-3687-49e5-a985-d6c0dbaf65e9',
  FRESHWORKS: '0471908e-43b7-455f-bb53-f31b17c6056f',
  FRESHWORKS_OIDC: '7c6a764a-7854-4d39-9405-c78c317a6bc7',
  HOOTSUITE: '5cbe14b8-f55e-4adb-9d43-0d64182c5bfa',
  HUBSPOT: '5316d104-8027-4156-a379-5c3dee09262e',
  HUDDLE: '1a3e91ed-fec2-4635-ad46-4a922b2707eb',
  IDG_DESKTOP: '00cb9c20-a401-4048-abbb-d7b9a3418349',
  IDAAS_ADFS: '7759eb80-7b89-11e9-8f9e-2a86e4085a59',
  IDAAS_DESKTOP: 'dbbe32a8-8065-11e9-bc42-526af7764f64',
  IDAAS_ISAPI: 'ac8e7051-e7c6-4a6f-92f2-cf30f107f54f',
  IDAAS_FORGEROCK: '2fa6e185-596d-4096-b48c-99f569b46156',
  ISAPI: '4ae110db-e996-45c2-ae12-6f17dd9a6a85',
  ISSUANCEAPI: 'bb68860d-21ce-4756-a5a4-ddfa33bb35cc',
  JAMF: '9887ca61-11cc-471d-aef9-b5d2afae4bf5',
  JENKINS: 'efd7917a-be47-464f-9e53-46546e07016c',
  KEEPERSECURITY: '9e789ee6-15cd-4e67-856b-2c2fbd0e3160',
  KNOWBE4: '750fbe66-d2e4-4698-8666-90bf8b2175e8',
  LEAVEWIZARD: '4692f072-705c-4c91-bf8d-67410104c53e',
  LOGMEIN: 'b03b1e5a-9339-48cf-b8b3-5666253bdc16',
  LUCIDCHART: '6f86daa5-eaa6-4916-bf34-9ded0fe5e984',
  MICROSOFT_EAM: 'a652d057-0a5a-46ed-b911-f878f994f7d7',
  MIMECAST: 'aa9760ae-0d15-4592-a374-acf7837c41e5',
  MIRO: '7f1ad58b-6d3b-45c0-bba0-7c5a7bbd2acd',
  MONDAYDOTCOM: 'c11911bc-6cd1-496e-be3e-b04c7e4dc611',
  MULESOFT: '3574887c-fbc4-4ece-a8ab-cca2ee1f95b4',
  NETMOTION: '505fdbb8-af45-4dce-9a6a-a22071300b92',
  NETSCALER: 'da38d1f6-e58c-11e7-80c1-9a214cf093ae',
  NETSKOPE: '333fc933-9d80-4521-81e2-3b9642055f85',
  NEWRELIC: 'ac7e6e4c-6696-4893-9b6a-e635f6e248a6',
  OFFICE365: '111fcc42-10d2-45b7-9ee5-86631ab5e966',
  ONFIDO: '8465be2a-e4aa-4c06-a1fe-f4bba4c36ede',
  OPENIDCONNECTWEB: '91f777d4-0dd1-4844-966c-d42ab3a58fa7',
  OPENIDCONNECTSPA: '12e2b5ee-2e1e-4237-b77d-c5f7a9395fb9',
  OPENIDCONNECTSERVER: '5dbf879e-a304-4126-9abb-f6cce5b6c86e',
  OPENIDCONNECTNATIVE: '3fe486ba-81f9-4ddf-8c8d-bf7572177b1f',
  OPENIDCONNECTDEVICE: 'f9f748fe-0a52-4ad5-9ec5-2f667062b644',
  OID4VCI: '32086c2d-d7ba-483e-b475-3536bf930b24',
  OPENVPN: 'e25ff9c4-2cf9-4750-95aa-ecd01a1e8e50',
  ORACLEEPM: '841fbd57-6ebc-4ea7-ae93-7a538b6fbf6c',
  PAGERDUTY: 'dec78c2f-e5ce-466b-be76-404c0c202e27',
  PALOALTOSAML: '7e60a4be-d3f7-4a4f-8f17-841c9aea1a8f',
  PALOALTO: '34e601dc-b18e-45af-aa8e-c85d5c18cef7',
  PAM: 'a7d1ddaf-eb68-4cbc-9f1d-0ff4e6f4e9ea',
  PINGDOM: '1d123c98-6b95-4a43-86a2-a596e26f02d5',
  PRODPAD: '97ff8475-abaa-4413-83a5-e51c3035207c',
  PULSESECURE: '0af5dbc9-d14b-4d9f-9253-e2c853ba11ea',
  RADIUS: '55e51625-05cd-4074-9371-3244d67e3920',
  REVIEWINC: '79f0c757-4c9a-4fb7-b257-129373e90af8',
  RINGCENTRAL: '63414c3d-19d3-48cf-b63d-32eca8376099',
  SAILPOINT: '74b0d73d-36dd-4c48-b526-5e2ae0c1fa99',
  SALESFORCE_OIDC: 'ca32eee6-3274-4b28-9ec3-8c6c75a7c6e1',
  SALESFORCE_SAML: '90a22a2b-ecba-4def-a5e7-89e8d9d46c48',
  SAML: '76132cda-3c42-4ed3-8d02-8d062368c472',
  SERVICENOW: '264e145a-3ee9-4fd4-9934-bee0e91e581c',
  SHAREFILE: 'ccbe31c8-730b-4d2d-87be-2abc066646ca',
  SHOPIFYPLUS: 'd6e12e3e-8603-47f1-b855-8b5012d6ba4a',
  SIEMAPI: '10f2aecb-1703-45d9-94e5-d27aa6a393a9',
  SIEMSYSLOG: 'c37c29fe-9173-4a37-ba9f-7514cca672e9',
  SITEMINDER: '092e48c5-88ec-4182-bf0c-4f85e28a339b',
  SLACK: '4a129d88-d59b-4bff-9ce2-a7d247415a43',
  SMARTSHEET: '2e18632f-7a2f-4897-9a90-5102af93f971',
  SNOWFLAKE: '4bf39193-42a8-485b-ab50-3991fb3f854f',
  SOLOINSIGHT: '53462a46-d045-4269-815b-239125835a3c',
  SONICWALL: '746a40bf-b67a-4b75-9a0a-bcbaf09ca4ab',
  SOPHOS: 'c09dd636-4615-4ee2-b586-abeda594c4d6',
  SP: '003d47dd-4183-4caa-a1a9-5e5f9218e991',
  SPLUNK: '02eb330c-6d06-4451-8b7f-db8b65d1d6cf',
  SPLUNKSOAR: '53f4d92c-62d7-4b28-b846-43c110dcdf68',
  SUMOLOGIC: '0b8192df-5ca9-4ab5-9854-de0c760ece41',
  TABLEAU: '49db351d-a5dc-4e8c-ae90-190d19f8198d',
  USER_PORTAL: '7593dfb5-dd90-49b2-9dd8-b69b28d36f27',
  USER_PORTAL_EDIT: '2d3040b7-9642-4e1f-bc5b-a5ce39f191b0',
  VMWARE: 'c8410c04-1976-4317-846a-1585082bd0ed',
  WEBEX: 'c92d44af-1767-43f3-a455-db3ebea821fb',
  WHITESOURCE: '4f005adc-9151-47a8-af1b-fd74d6772b9e',
  WORKDAY: '7082ea5c-6ec5-4af5-be3d-4f0e837f1348',
  WORKFRONT: '288fffa2-683c-4e3d-b456-d812cd0c8eb0',
  ZIFLOW: '3fdfe0dc-ea27-4d27-bb0e-c3c47e3c68bb',
  ZENDESK: 'd402141d-16db-4b74-8c45-7326e692d187',
  ZOHOONE: '625bd8dd-f57d-4b3f-a2b4-8c860f3fd5cf',
  ZOOM: '6f4f6d28-0fd5-416e-99c1-2566c3902d3d',
  ZSCALER: '112377f6-270d-4d52-8a09-9bc99ed0c21c',
  ZUORA: '106b81e1-5c5d-47a6-97ca-96fd991ed343'
});

export const AUTHENTICATION_FLOWS = {
  ACCESS_DENIED: '592d60dd-f1ef-4803-8e62-1e5663189e61',
  DEFAULT_SECOND_FACTOR: '21866f63-ef60-4d02-8f96-a87a131f76f7',
  DOMAIN_BASED_IDP: 'c2836373-1e85-4c5e-b657-f102c25ab4af',
  EXTERNAL_SECOND_FACTOR: '72fd8717-fffe-462f-83c6-131c12539af7',
  PASSWORD_ONLY: 'fb9faf5d-8c16-4775-a791-d2d037ec5a68',
  PASSWORD_SOFT_HARDWARE_TOKEN: '89fe4b3f-d9bf-46b0-a938-5250b2aa3a30',
  PASSWORD_SECOND_FACTOR: '530a1ef6-8358-4813-af15-09a128c739d0',
  SOFT_HARDWARE_TOKEN_OTP: '831f878f-5e98-4885-b5eb-97810bfb7bd2',
  SOFT_HARDWARE_TOKEN: '47386620-243e-4b54-8ec5-0eaff5020ce9',
  SMART_LOGIN_ID: '7869f2e1-96e8-4423-a8c8-2317b2205fa6',
  USER_CERTIFICATE_LOGIN_ID: 'a6cb609f-c6ea-48ad-ab61-433b4054a1f8'
};

export const ISSUANCE_ONLY_FLOWS = [AUTHENTICATION_FLOWS.PASSWORD_ONLY, AUTHENTICATION_FLOWS.DEFAULT_SECOND_FACTOR];

export const ISSUANCE_APPS = [APPS.ISSUANCEAPI];

// Maps Entrust IDaaS System Attribute IDs to default Active Directory Object Attributes/
export const DEFAULT_ATTRIBUTE_MAPPINGS = {
  'f0815256-e011-49ff-8bfc-c960ef79785c': 'mail',
  '3f8cb5ac-c5f1-4642-8470-1528ce68ab8f': 'givenName',
  '1609ce97-5aa8-45cd-a74c-fa8b244d252f': 'sn',
  '797e5166-7b5b-480a-9587-2155a9c40bfc': 'sAMAccountName',
  '687fe072-fcbc-46c2-ab61-e80b568d077f': 'mobile',
  'a5234161-62fe-4a7b-9584-3e0fcfd5ef5b': 'userAccountControl',
  '74750977-a59f-4b84-aa26-687d36f5001f': 'phone',
  '333c90fc-9284-4be3-8644-6a6e15a8d968': 'userPrincipalName',
  '4f0ef004-9c18-11ed-a8fc-0242ac120002': 'securityId'
};

// keep in sync with UserAttributeEntity.java in lib-jpa and in lib-directory-sync
export const SYSTEM_USER_ATTRIBUTES = {
  email: 'f0815256-e011-49ff-8bfc-c960ef79785c',
  firstname: '3f8cb5ac-c5f1-4642-8470-1528ce68ab8f',
  lastname: '1609ce97-5aa8-45cd-a74c-fa8b244d252f',
  mobile: '687fe072-fcbc-46c2-ab61-e80b568d077f',
  uniqueUserid: 'b59cd419-aef5-4b11-9729-adafb0ca32a2',
  userid: '797e5166-7b5b-480a-9587-2155a9c40bfc',
  state: 'a5234161-62fe-4a7b-9584-3e0fcfd5ef5b',
  phone: '74750977-a59f-4b84-aa26-687d36f5001f',
  userPrincipalName: '333c90fc-9284-4be3-8644-6a6e15a8d968',
  securityId: '4f0ef004-9c18-11ed-a8fc-0242ac120002',
  locale: '82f1b03c-b324-466e-8d3a-53c1bfebcb97'
};

// System Attribute ID's for Unique User ID & Locale which cannot be set using AD Connector, Azure and Active Directory
export const DEFAULT_SYSTEM_USER_ATTRIBUTES_NOT_MAPPED_IN_DIRECTORY = [
  SYSTEM_USER_ATTRIBUTES.uniqueUserid,
  SYSTEM_USER_ATTRIBUTES.locale
];

// From EntitlementEntity.java
export const MAX_ENTITLEMENT_QUANTITY = 2147483647;

// Keep in sync with DevicePlatformType.java
export const DEVICE_FINGERPRINT_PLATFORM_ANDROID = 'Android';
export const DEVICE_FINGERPRINT_PLATFORM_IOS = 'iOS';
export const DEVICE_FINGERPRINT_PLATFORM_WEB = 'web';

// Directory types
export const ON_PREM_DIR_TYPE = 'on_prem';
export const ON_PREM_LDAP_DIR_TYPE = 'on_prem_ldap';
export const ADCONNECTOR_DIR_TYPE = 'ad_connector';
export const AZURE_DIR_TYPE = 'azure';

// Identity Provider types
export const GENERIC_IDP = 'GENERIC';
export const IDV_IDP = 'IDV';
export const MICROSOFT_IDP = 'MICROSOFT';
export const GOOGLE_IDP = 'GOOGLE';
export const FACEBOOK_IDP = 'FACEBOOK';
export const TWITTER_IDP = 'TWITTER';

// Identity Provider constants
export const NAMEID_PARAMETER_IDP = 'NameID';

// Provisioners types
export const GENERIC_SCIM = 'GENERIC';
export const SALESFORCE_SCIM = 'SALESFORCE';

// Mobile Flash Pass
export const ALLOWED_IMAGE_FORMATS = '.jpg,.jpeg,.png';
export const BARCODE_TYPES = Object.freeze({
  CODE128: {
    format: 'CODE128',
    appleSupport: true
  },
  CODE39: {
    format: 'CODE 39',
    appleSupport: false
  },
  EAN13: {
    format: 'EAN 13',
    appleSupport: false
  },
  PDF417: {
    format: 'PDF417',
    appleSupport: true
  },
  QRCODE: {
    format: 'QR Code',
    appleSupport: true
  },
  NONE: {
    format: 'None',
    appleSupport: true
  }
});
export const DEFAULT_MFP_EXPIRY = 30;
export const FULL_WIDTH_IMAGE_MAX_WIDTH = 450;
export const FULL_WIDTH_IMAGE_MAX_HEIGHT = 375;
export const FULL_WIDTH_IMAGE_PRESCRIBED_WIDTH = 1860;
export const FULL_WIDTH_IMAGE_PRESCRIBED_HEIGHT = 375;
export const LOGO_IMG_DIMENSION = 200;
export const STRIP_IMAGE_MAX_WIDTH = 450;
export const STRIP_IMAGE_MAX_HEIGHT = 450;
export const STRIP_IMAGE_PRESCRIBED_WIDTH = 375;
export const STRIP_IMAGE_PRESCRIBED_HEIGHT = 98;
export const DFN_ALLOW_LIST_MAX_ACCOUNT = 20;

// Issuance Direct Tenant Accounts
export const DIRECT_TENANT_ACCOUNT_STATUS_DELAY = 30000;
export const ISSUANCE_ADDON_TYPES = {
  PRINTER_CERT_ADDON: 'printerCert',
  DIRECT_TENANT_ADDON: 'directTenant'
};

// Webhooks
export const WEBHOOK_RESOURCE_TYPES = {
  CREDENTIAL: 'credential'
};
export const WEBHOOK_STATE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};
export const MAX_ACTIVE_WEBHOOKS = 20;
export const WEBHOOK_EVENT_TYPE_DELIMETER = '.';
export const WEBHOOK_MAX_EXPIRY = 604800; // 1 week (in seconds)
export const WEBHOOK_MIN_EXPIRY = 3600; // 1 hour (in seconds)

// Contract Modes
export const CONTRACT_MODES = {
  PRODUCTION: 'PRODUCTION',
  TRIAL: 'TRIAL',
  UNKNOWN: 'UNKNOWN'
};

// These are the ISO 639-1 compliant languages that are used in the LanguageSelector
export const LANGUAGE_DEFAULT = {
  da: 'Dansk (da)',
  de: 'Deutsch (de)',
  es: 'Español (es)',
  en: 'English (en) ',
  fr: 'Français (fr)',
  it: 'Italiano (it)',
  ja: '日本語 (ja)',
  ko: '한국어 (ko)',
  nb: 'norsk (nb)',
  nl: 'Nederlands (nl)',
  pl: 'polski (pl)',
  pt: 'Português (pt)',
  ru: 'Русский (ru)',
  sv: 'Svenska (sv)',
  th: 'ภาษาไ (th)',
  tr: 'Türkçe (tr)',
  'zh-cn': '简体中文 (zh-cn)',
  'zh-tw': '‪繁體中文‬ (zh-tw)'
};

// Issuance accounts compliant languages that are used in the LanguageSelector
export const ISSUANCE_LANGUAGE_DEFAULT = {
  en: 'English (en)',
  es: 'Español (es)',
  fr: 'Français (fr)',
  ja: '日本語 (ja)',
  pt: 'Português (pt)'
};

// Used in groups page
export const ROLE_TYPE_ACCOUNT_MANAGER = 'ACCOUNT_MANAGER';
export const ROLE_TYPE_SITE_ADMINISTRATOR = 'SITE_ADMINISTRATOR';
export const SITE_HELP_DESK_ADMIN_ROLE_ID = 'Help Desk Administrator';
export const SERVICE_PROVIDER_ROLE_NAMES = ['Super Account Manager', 'Audit', 'On-boarding Administrator'];
export const SERVICE_PROVIDER_SUPER_ACCOUNT_MANAGER_ROLE_NAME = 'Super Account Manager';
export const SERVICE_PROVIDER_AUDIT_ACCOUNT_MANAGER_ROLE_NAME = 'Audit';

// Application IDs
export const USER_PORTAL_APPLICATION_ID = '7593dfb5-dd90-49b2-9dd8-b69b28d36f27';
export const USER_PORTAL_EDIT_APPLICATION_ID = '2d3040b7-9642-4e1f-bc5b-a5ce39f191b0';
export const ADMIN_PORTAL_APPLICATION_ID = '6116cdc9-08e8-4450-a948-2ff62b4ea2ad';
export const PASSWORD_RESET_APPLICATION_ID = '6b980058-a68a-4e2c-9dc8-83e488cc8d46';
export const GENERIC_SERVER_APPLICATION_ID = '5dbf879e-a304-4126-9abb-f6cce5b6c86e';

// Role IDs
export const SUPER_ADMINISTRATOR_ROLE_ID = 'c35a6ab0-5f72-43db-af35-b85e4c9874f3';
export const SITE_AUDITOR_ROLE_ID = '5b83bedc-3632-4a74-90c9-e721472ab6d9';
export const SITE_SIEM_ADMIN_ROLE_ID = '9a7ab2b7-1541-47a5-adc8-183d73987c54';
export const SITE_HELP_DESK_ROLE_ID = 'fb680df6-155e-4a48-be91-7c08bf1ca502';
export const SITE_ISSUANCE_ADMIN_ROLE_ID = 'bac3a255-91c4-4c72-b68c-333e9cafd621';
export const SITE_ISSUANCE_DESIGNER_ROLE_ID = 'd1f093b8-287c-4358-b1ab-e5918ff3f437';
export const SITE_ISSUANCE_SUPERVISOR_ROLE_ID = '62466a6b-7396-4314-9dea-160ad1b4f347';
export const SITE_ISSUANCE_OPERATOR_ROLE_ID = 'f51ce5aa-6030-49d8-9f85-ddc454ba3eea';
export const SITE_ISSUANCE_BASIC_SUPPORT_ROLE_ID = 'db106d63-561f-42fa-b145-cd114c9b4f42';
export const SITE_ISSUANCE_ENHANCED_SUPPORT_ROLE_ID = 'b0b501c1-076c-4038-b71e-99340a2f787d';
export const SERVICE_PROVIDER_API_ACCOUNT_ONBOARDING_ROLE_ID = '93f6e1ea-6ff9-4f47-a666-94c9ed7d451d';
export const ACCOUNT_MANAGER_ROLE_ID = '53a5b169-d2ef-4a90-8405-b33cc81b7641';
export const SERVICE_PROVIDER_AUDIT_ACCOUNT_MANAGER_ROLE_ID = '2050808c-019c-460e-8b72-df8e4d26613f';
export const SERVICE_PROVIDER_ENTITLEMENTS_ROLE_ID = '76711250-a7d0-4b80-93f7-cc23e914e8bf';
export const SERVICE_PROVIDER_ONBOARDING_ACCOUNT_MANAGER_ROLE_ID = '8c68d5ba-5b8c-4550-bff6-6b9fefddcfd4';
export const SERVICE_PROVIDER_CUSTOMER_SUPPORT_AGENT_ROLE_ID = 'bb4482b0-5848-4be8-9e98-ac1b50825ae0';
export const SERVICE_PROVIDER_SP_IDENTITY_PROVIDER_ROLE_ID = 'a0c31353-a139-4ef9-8b81-3769742f9232';
export const SERVICE_PROVIDER_SUPPORT_ACCOUNT_MANAGER_ROLE_ID = '9be7acda-7339-45dd-9d10-daefea983ee6';
export const SITE_AD_CONNECTOR_ROLE_ID = '8b4db0b2-a045-43fb-bd9f-dc99e8664a09';

export const AUTHENTICATION_ONLY_ROLES = [SITE_AD_CONNECTOR_ROLE_ID, SERVICE_PROVIDER_SP_IDENTITY_PROVIDER_ROLE_ID];

export const ISSUANCE_ONLY_ROLES = [
  SITE_ISSUANCE_ADMIN_ROLE_ID,
  SITE_ISSUANCE_DESIGNER_ROLE_ID,
  SITE_ISSUANCE_SUPERVISOR_ROLE_ID,
  SITE_ISSUANCE_OPERATOR_ROLE_ID,
  SITE_ISSUANCE_BASIC_SUPPORT_ROLE_ID,
  SITE_ISSUANCE_ENHANCED_SUPPORT_ROLE_ID,
  SERVICE_PROVIDER_SUPPORT_ACCOUNT_MANAGER_ROLE_ID
];

export const PERMISSION_ENTITY_AUTHENTICATION_ONLY = [
  'ACTIVESYNC',
  'ARCHIVES',
  'BULKGROUPS',
  'BULKHARDWARETOKENS',
  'BULKSMARTCARDS',
  'BULKUSERS',
  'BULKIDENTITYGUARD',
  'CAS',
  'CONTACTVERIFICATION',
  'INTELLITRUSTDESKTOPS',
  'DIGITALIDCONFIGS',
  'DIRECTORIES',
  'DIRECTORYPASSWORD',
  'DOMAINCONTROLLERCERTS',
  'FACE',
  'FIDOTOKENS',
  'GRIDS',
  'GRIDCONTENTS',
  'HOSTNAMESETTINGS',
  'IDENTITYPROVIDERS',
  'MAGICLINKS',
  'MAGICLINKCONTENTS',
  'OAUTHROLES',
  'OTPS',
  'ORGANIZATIONS',
  'PASSTHROUGH',
  'SENDAZUREAD',
  'SMARTCRRDS',
  'RESOURCESERVERAPIS',
  'RESOURCESERVERSCOPES',
  'SCDEFNS',
  'SMARTCREDENTIALS',
  'SMARTCREDENTIALSSIGNATURE',
  'SERVICEPROVIDERACCOUNTS',
  'SERVICEPROVIDERS',
  'SPIDENTITYPROVIDERS',
  'USERMACHINES',
  'USEROAUTHTOKENS',
  'USERQUESTIONS',
  'USERQUESTIONANSWERS',
  'USERRBASETTINGS',
  'SCIMPROVISIONINGS',
  'VCDEFNS',
  'VCS',
  'VPDEFNS'
];

export const PERMISSION_ENTITY_ISSUANCE_ONLY = [
  'BULKENROLLMENTS',
  'CREDENTIALDESIGNS',
  'ENROLLMENTDESIGNS',
  'ENROLLMENTS',
  'ISSUANCE',
  'PRINTERS',
  'SMARTCARDS'
];

export const HTTP_METHODS = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];

export const AUTH_TYPES = [
  { name: 'None', value: 'NONE' },
  { name: 'Basic', value: 'BASIC' },
  { name: 'Bearer Token', value: 'BEARER_TOKEN' },
  { name: 'API Key', value: 'API_KEY' }
];

export const API_KEY_TYPES = ['BEARER', 'BASIC'];

export const PERMISSION_ENTITY_PREMIUM_BUNDLE_ONLY = ['DOMAINCONTROLLERCERTS'];

export const PERMISSION_ENTITY_PREMIUM_CONSUMER_BUNDLE_ONLY = [
  'DIGITALIDCONFIGS',
  'IDENTITYPROVIDERS',
  'SCDEFNS',
  'SMARTCREDENTIALS',
  'SMARTCREDENTIALSSIGNATURE'
];

export const PERMISSION_ENTITY_DESCRIPTION = {
  ACTIVESYNC: 'Active Sync Device Management',
  ALL: 'Administrative Entity encompassing all other Entities',
  AGENTS: 'Enterprise Gateway and Agents Management',
  APPLICATIONS: 'Applications Management',
  ARCHIVES: 'Archive Management',
  AUTHORIZATIONGROUPS: 'Authorization Groups Management',
  BULKENROLLMENTS: 'Bulk Enrollments',
  BULKGROUPS: 'Bulk Group Operations',
  BULKHARDWARETOKENS: 'Bulk Hardware Token Operations',
  BULKSMARTCARDS: 'Bulk Smart Card Operations',
  BULKUSERS: 'Bulk User Operations',
  BULKIDENTITYGUARD: 'Bulk IdentityGuard Operations',
  CAS: 'Certificate Authority Manangement',
  CONTEXTRULES: 'Resource Rules Management',
  CREDENTIALDESIGNS: 'Credential Design Management',
  INTELLITRUSTDESKTOPS: 'User Desktop Management',
  DIGITALIDCONFIGS: 'Digital ID Management for Smart Credentials',
  DIRECTORIES: 'Directories and Directory Sync',
  DIRECTORYPASSWORD: 'Directory Password',
  EMAILTEMPLATES: 'Email Template Management',
  ENROLLMENTS: 'Enrollments',
  ENTITLEMENTS: 'Account Entitlement Status',
  EXPORTREPORTS: 'Export Reports',
  FACE: 'Face Management',
  FIDOTOKENS: 'User FIDO2 Token Management',
  GRIDS: 'User Grid Card Management',
  GRIDCONTENTS: 'User Grid Card Content Management',
  GROUPS: 'Groups Management',
  HOSTNAMESETTINGS: 'Account Hostname Management',
  IDENTITYPROVIDERS: 'Identity Providers Management',
  ISSUANCE: 'Issue Credentials',
  OAUTHROLES: 'OAuth Roles',
  OTPS: 'OTP Management',
  PASSTHROUGH: 'Passthrough Authenticator Management',
  SMARTCRRDS: 'Smart CardS',
  PRINTERS: 'Printer Management',
  REPORTS: 'Account Reports',
  ROLES: 'Roles Management',
  RESOURCESERVERAPIS: 'Resource Servers',
  RESOURCESERVERSCOPES: 'Resource Server Scopes',
  SCDEFNS: 'Smart Credential Definition Management',
  SCHEDULEDTASKS: 'Scheduled Task Management',
  SMARTCARDS: 'Smart Card Configuration',
  SMARTCREDENTIALS: 'User Smart Credential Authenticator Management',
  SMARTCREDENTIALSSIGNATURE: 'User Smart Credential Signature',
  SERVICEPROVIDERACCOUNTS: 'Unattended Service Provider Management',
  SERVICEPROVIDERS: 'Service Provider Management',
  SETTINGS: 'Account and Authenticator Settings',
  SUBSCRIBERS: 'Account Branding Customization',
  TEMPACCESSCODES: 'User Temporary Access Code Management',
  TEMPACCESSCODECONTENTS: 'User Temporary Access Code View Value',
  TEMPLATES: 'Application Template Management',
  TOKENACTIVATIONCONTENTS: 'Entrust Soft Token Manual Activation Details',
  TOKENS: 'User Token Authenticator Management',
  USERATTRIBUTES: 'User Attribute Management',
  USERMACHINES: 'User Machine ID Authenticator Management',
  USEROAUTHTOKENS: 'User OAuth Tokens Management',
  USERPASSWORDS: 'User Password Authenticator Management',
  USERQUESTIONS: 'User Knowledge-based Authenticator Management',
  USERQUESTIONANSWERS: 'User Knowledge-based Authenticator View Answers',
  USERRBASETTINGS: 'User Risk Based Authentication Management',
  USERS: 'User Management',
  USERSITEROLES: 'User Role Management',
  USERSPROLES: 'Service Provider Role Management',
  ENROLLMENTDESIGNS: 'Enrollment Design Management',
  SCIMPROVISIONINGS: 'Outbound Provisioning Management',
  MAGICLINKS: 'Magic Link Management',
  MAGICLINKCONTENTS: 'Magic Link Content Management',
  WEBHOOKS: 'Webhook Management',
  VCDEFNS: 'Verifiable Credential Definition Management',
  VCS: 'Verifiable Credential Management',
  VPDEFNS: 'Verifiable Presentation Definition Management'
};

// keep in sync with NameIdFormatEntity.java in lib-jpa
export const NAMEIDFORMATIDS = {
  persistent: '1aabcbdb-f622-4120-8c73-e72931376350',
  unspecified: '3fb2cf55-e48c-49c8-b673-039b1b97b024',
  email: 'c8a684fa-f732-4fbd-9ab2-bb19fc83297e',
  transient: '34acc94c-a468-4499-b73b-9063f924dd37',
  kerberos: '0dc83b9a-0b65-4429-b705-0cf93ba9aa52',
  x509: 'b8af3bc5-1378-4913-82f4-545d52f28aca',
  windows: 'fa277a1c-4ba0-4236-8e47-d588bec75479'
};

// keep in sync with SignatureAlgorithmEntity.java in lib-jpa
export const SIGNATUREALGORITHMIDS = {
  SHA1: '9ea1883b-cedb-4656-8872-1a1ea0bb95ad',
  SHA256: 'ee8a6540-0a33-43b0-9ce1-2265cb14cee9',
  SHA512: '62d48cd4-3392-4fbd-bf48-6d28a568fca0'
};

// keep in sync with OidcScopesEntity.java in lib-jpa
export const OIDCSCOPES = {
  openid: '8f9918c4-3a38-42d9-8fc1-b2bd475f7ee5',
  email: '3d13c1d7-7e69-4a89-846e-0d5b509034d7',
  phone: '382766c7-c663-4a55-b5f9-d6864ed59d9a',
  address: '072791f4-e3a7-40f8-b951-84416eeadd79',
  profile: 'fe2ab74b-f0d1-4350-bc43-4bcedc2f66e1'
};

// keep in sync with OidcClaimsEntity.java in lib-jpa
export const OIDCCLAIMS: Record<string, string> = {
  acr: '572a00bf-9052-4f9e-904e-8c2bc65dc142',
  'address:country': '535f5db2-1c90-4a16-9e44-53749afe4f6a',
  'address:formatted': 'adf88e82-e36b-4149-bc04-97b8334dab7e',
  'address:locality': '97cad7b8-e9a4-497e-a314-6e8a8ac8f2bd',
  'address:postal_code': '41baad06-39b0-4ed0-bb36-32b0f078bd03',
  'address:region': 'e7ad968e-e2af-44bb-8374-9a81d8b64e8f',
  'address:street_address': '739f067e-b8fd-4144-9e13-08f3b8350b1c',
  aud: 'f117add2-ad3e-4cf2-bb76-0173ba72cd8f',
  auth_time: 'ebac00d5-5052-416f-9948-5151ddcf511a',
  birthdate: '84a20e4c-9912-41b5-bc45-788129c49b16',
  email: '02a805a4-6456-4408-bf63-00c819ade782',
  email_verified: '3cdbd708-bcc3-4a5f-9ba0-7be599ff4a0f',
  exp: 'addaff14-521d-4c08-9a75-07536d420047',
  family_name: '23c19ece-0ee6-498e-afc5-084d1e31b516',
  gender: 'd2f3d244-776b-474c-8271-e3174e866b12',
  given_name: '3e1a7b53-6d59-4175-b7d8-a62b21fd4d81',
  iat: '7a234eb1-ca8b-491e-a371-8c291fe7423d',
  iss: 'afd0335c-c3ad-433b-8527-eed90674cc7f',
  locale: 'beb2cbc6-ddba-4723-bfa5-2d87b4b0ec1e',
  middle_name: 'ca70f2a1-7a9a-454c-ab86-1e64a0e17b67',
  name: '01463812-a0a5-484f-9024-87ac75a938a0',
  nickname: '6ff5361f-1772-45f3-a19e-895b4e349218',
  phone_number: '038a6540-0a33-43b0-9ce1-2265cb14cee9',
  phone_number_verified: '1aa0cc57-64ea-4137-9cc9-e856ff5fbdbe',
  picture: 'd9bdc27e-a7bf-47b5-b93d-5cd16e4c6888',
  preferred_username: '5a8ccac0-4f75-49c3-86d9-15636497abe3',
  profile: 'ee0e7287-f632-481b-8b44-41d0f6af99e8',
  scope: '0ffcca63-fd53-4d17-bec6-b7b3507a9e18',
  sub: 'c1700027-f152-4a8b-aab4-64999e351e70',
  updated_at: '810c466c-3c86-44d3-9cde-f65119e9ca88',
  website: '0409ebb3-7cda-436d-a8b1-f216160cef71',
  zoneinfo: '681d228b-2eb7-436a-8023-d95014a82cb1',
  // custom
  amr: '6dfc5f84-cd70-4bd9-9352-e7e937d23d30',
  app: '8b791edc-0d9b-4124-adaa-9e6072ac01d0',
  authenticators: '5ae50875-6074-42df-9aa0-ed69ee961d58',
  cid: '1c80f644-2959-4dfa-a0e4-3c00edb9c120',
  entitlements: 'e6ce71cd-9e17-4cac-b217-1260bfee83bb',
  groups: '034adbc7-6f62-43b4-a68a-864f836be49b',
  mfa: 'fae7b5cc-b886-4481-ab76-c5622514e42e',
  org_id: '24f1c467-8374-4425-ad46-709775a6ebc1',
  permissions: 'dde3dc19-25d8-4ec2-82c1-7fb2b1d4c7a7',
  role: '072791f4-e3a7-40f8-b951-84416eeadd79',
  roles: 'bad4440d-c53e-4a19-a6fd-8b49c587b4f3',
  tid: 'd13204a8-2051-4dce-820a-e87d6b26ce0c',
  transaction_details: 'd6e2ba3f-29c4-4c0b-88dd-ab2b8bab2e88',
  upn: '043c7d71-dd3b-452b-9395-6f81d0bff7e6'
};

export const DOC_LINKS = Object.freeze({
  ACTIVESYNC_ACCESS_HELP_URL:
    '/documentation/help/admin/index.htm#t=Active_Sync_Access%2FManage_Active_Sync_Access.htm',
  ADFS_HELP_URL: '/documentation/help/tigs/index.htm#t=Integrate_Identity_as_a_Service_AD_FS_Adaptor.htm',
  ADMIN_HELP_URL: '/documentation/help/admin/index.htm',
  ADMINDOC_URL: '/help/developer/apis/administration/openapi/',
  APACHE_HELP_URL: '/documentation/help/tigs/index.htm#t=Integrate_Identity_as_a_Service_Apache_Filter.htm',
  AUTHDOC_URL: '/help/developer/apis/authentication/openapi/',
  FIDO_HELP: '/documentation/help/admin/index.htm#t=Authenticators%2FPasskey%2FManage_Passkey_authenticators.htm',
  FORGEROCK_HELP_URL:
    '/documentation/help/tigs/index.htm#t=Integrate_Identity_as_a_Service_got_ForgeRock_Access_Manager.htm',
  GATEWAY_HELP_URL: '/documentation/help/admin/index.htm#t=Gateways%2FManage_Gateways.htm',
  GRID_CARD_HELP: '/documentation/help/admin/index.htm#t=Authenticators%2FGrids%2FManage_grids.htm',
  INTELLITRUST_DESKTOPS_HELP_URL:
    '/documentation/help/tigs/index.htm#t=Integrate_Identity_as_a_Service_Desktop_for_Windows.htm',
  INTEGRATION_HELP_URL: '/documentation/help/tigs/index.htm#t=Entrust_Identity_as_a_Service_Integration_Guides.htm',
  ISAPI_HELP_URL: '/documentation/help/tigs/index.htm#t=Integrate_Identity_as_a_Service_ISAPI_Filter.htm',
  ISSUANCE_RESOURCE_HELP_URL: '/documentation/help/issuance/Manage_resources.htm',
  ISSUANCE_ENABLE_PRINTING_URL: '/documentation/help/issuance/Enable_Cloud_Printing.htm',
  ISSUANCE_HELP_URL: '/documentation/help/issuance/Default.htm',
  ISSUANCE_SP_HELP_URL: '/documentation/help/issuanceSP/Default.htm',
  ISSUANCEDOC_URL: '/help/developer/apis/issuance/openapi/',
  MSCAPROXY_HELP_URL: '/documentation/help/admin/index.htm#t=Certificate_Authorities%2FConfigure_Microsoft_CA.htm',
  MSP_TOS_URL:
    'https://www.entrust.com/-/media/documentation/licensingandagreements/identity-as-a-service-tos-msp-model.pdf',
  OSS_URL: '/documentation/legal/OSS-statement.html',
  PORTAL_URL: '/help/developer/',
  PP_URL: 'https://www.entrust.com/legal-compliance/data-privacy/privacy-statement',
  RELEASE_NOTES_URL_AUTHENTICATION: '/help/authentication',
  RELEASE_NOTES_URL_ISSUANCE: '/help/issuance',
  RELEASE_NOTES_URL: '/help',
  CORS_HELP_URL: '/documentation/help/admin/index.htm#t=Manage_CORS.htm',
  RESOURCE_DEVICE_FINGERPRINT: '/documentation/help/admin/index.htm#t=Authenticators%2FDevice_fingerprint.htm',
  RESOURCE_RULES_HELP: '/documentation/help/admin/index.htm#t=Protect_resources.htm',
  SOFT_TOKEN_HELP: '/documentation/help/admin/index.htm#t=Authenticators%2FSoftToken%2FModify_Entrust_ST_settings.htm',
  SP_HELP_URL: '/documentation/help/sp/index.htm',
  TIGS_HELP_URL: '/documentation/help/tigs/index.htm',
  TOS_ISSUANCE_URL:
    'https://www.entrust.com/-/media/documentation/licensingandagreements/entrust-adaptive-issuance-instant-id-as-a-service-tos.pdf',
  TOS_URL: 'https://www.entrust.com/-/media/documentation/licensingandagreements/identity-as-a-service-schedule.pdf',
  USER_HELP_URL: (locale: string) => `/documentation/help/user/${locale}/index.htm`
});

export const OIDC_LINKS = Object.freeze({
  OAUTH_CONFIG_URL: '/api/oidc/.well-known/oauth-authorization-server',
  OIDC_CONFIG_URL: '/api/oidc/.well-known/openid-configuration',
  JWKS_URL: '/api/oidc/jwks',
  LOGOUT_URL: '/api/oidc/endsession',
  ERROR_URL: '/api/oidc/errors',
  AUTHORIZATION_JWT_URL: '/api/oidc/authorizejwt'
});

export const OID4VC_LINKS = Object.freeze({
  OID4VCI_CONFIG_URL: '/api/oidc/.well-known/openid-credential-issuer',
  OID4VCI_DID_DOCUMENT_URL: '/api/oidc/did.json'
});

export const SAML_LINKS = Object.freeze({
  SOAP_URL: '/api/saml/SAML2/SSOSoap/',
  ENTITY_ID_URL: '/api/saml',
  SSO_URL: '/api/saml/SAML2/SSO',
  SLO_URL: '/api/saml/SAML2/SLO'
});

// CSR key types
export const CSR_KEY_TYPE_RSA_2048 = 'RSA_2048';
export const CSR_KEY_TYPE_RSA_3072 = 'RSA_3072';
export const CSR_KEY_TYPE_RSA_4096 = 'RSA_4096';

// CSR signing algorithms
export const CSR_SIGNING_ALGORITHM_RSA_256 = 'RSA_256';
export const CSR_SIGNING_ALGORITHM_RSA_384 = 'RSA_384';
export const CSR_SIGNING_ALGORITHM_RSA_512 = 'RSA_512';
export const CSR_SIGNING_ALGORITHM_RSA_PSS_MGF1_SHA_256 = 'RSA_PSS_MGF1_SHA_256';
export const CSR_SIGNING_ALGORITHM_RSA_PSS_MGF1_SHA_384 = 'RSA_PSS_MGF1_SHA_384';
export const CSR_SIGNING_ALGORITHM_RSA_PSS_MGF1_SHA_512 = 'RSA_PSS_MGF1_SHA_512';

export const AD_CONNECTOR_DOWNLOAD_LINK = 'https://www.entrust.com/-/media/identity-as-a-service/IDaaSAdConnector.zip';

// TODO: remove. Only the keys are used in RR General Settings
export const SYSTEM_RESOURCE_RULES = Object.freeze({
  'e4729943-888b-429f-86e3-286a3d407f67': 'User Portal - All Users',
  '19d22b5b-1178-493a-9c02-95a4295aa2e8': 'Administration Portal - Administrators'
});

// Rest API errors.
export const PASSWORD_CHANGE_REQUIRED_ERROR_CODE = 'password_change_required';
export const ACCESS_DENIED = 'access_denied';
export const INVALID_WEB_SESSION = 'invalid_web_session';
export const USER_LOCKED = 'user_locked';
export const INVALID_RESPONSE_USER_LOCKED_ERROR_CODE = 'invalid_user_response_locked';
export const USER_INVALID_RESPONSE_CODE = 'invalid_user_response';

// keep in sync with RateLimitingPolicyEntity.java in lib-jpa
export const RATE_LIMITING_POLICIES = {
  DELETED_TENANT: '509a68f1-c70e-4b7e-a673-ae9e2fdae1ab',
  LOCKED_TENANT: 'ea9cff26-fcb6-4a29-a0cc-88cadd66ca0e',
  UNLIMITED: '74159177-6ff9-41ea-bb14-662b0b0ed4a6'
};
