//      
                                       

// Redux Actions
// =============================================================================
import { getIsOidcRoute, getIsIdpCompleteRoute, getIsSamlRoute } from 'src/ducks/router';

/**
 * Get the locale for the application.
 * @param  {Object} state The entire Redux state object
 * @return {String}       The locale to use.
 */
export const getLocale = (state       , location         )         => {
  // Get the user and account level locale settings
  const authLocale = state.themes.authLocale || false;
  const userLocale = state.userProperties.user.locale || false;
  const accountLocale = state.themes.subscriber.defaultLocale;
  const isAuthenticationAccount = state.themes.subscriber.authenticationAccount;

  const isConsentPageRoute = location
    ? getIsOidcRoute(location) || getIsIdpCompleteRoute(location) || getIsSamlRoute(location)
    : false;

  // Allow users and administrators to change the locale during oidc and idp flow
  if (isConsentPageRoute) {
    return authLocale || userLocale || accountLocale || 'en';
  }

  // if the user is an administrator then always return english
  if (isAuthenticationAccount && Object.keys(state.permissions.roles).length) {
    return 'en';
  }

  // if the user profile has loaded then the user is logged in. Don't consider the auth locale.
  if (state.userProperties.lastUpdated !== null) {
    return userLocale || accountLocale || 'en';
  }

  // the user profile has not loaded.
  // For Issuance account default to account locale instead of auth locale
  return isAuthenticationAccount ? authLocale || accountLocale || 'en' : accountLocale || 'en';
};
