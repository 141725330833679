import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';

// Entrust Components
// =============================================================================
import FinalBasicDialog from 'src/components/FinalBasicDialog';

// Material-UI Components
// =============================================================================
import { makeStyles } from '@material-ui/core/styles';

// External Functions
// =============================================================================
import t from 'src/i18n';

// API
// =============================================================================
import { renewJWTToken } from 'src/api/users';
import {
  getSessionExpiryTime,
  getSessionStartTime,
  isSystemLoggedIn,
  updateRenewedJWTToken,
  logoutJWT,
  isLoggedIn
} from 'src/lib/jwt';

const useStyles = makeStyles({
  root: {
    zIndex: 1500
  }
});

const MAX_TIME_REMAINING_IN_SESSION = 120000;
const MIN_SESSION_LIFETIME = 60;
const AUTO_RENEW_THRESHOLD = 15;

const IdleDialog: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [totalSeconds, setTotalSeconds] = useState(MIN_SESSION_LIFETIME);
  const [isRenewing, setIsRenewing] = useState(false);

  const classes = useStyles();

  const location = useLocation();

  const history = useHistory();

  const isBulkAdd = location.pathname.startsWith('/bulkoperations/add');

  // Timeout will call the onIdle function
  const timeout = getSessionExpiryTime() - getSessionStartTime();

  const thirdSessionLength = Math.floor(timeout / 3);

  // Prompt the user at most 120s before expiry otherwise use 1/3 of the timeout session
  const promptBeforeIdle =
    thirdSessionLength > MAX_TIME_REMAINING_IN_SESSION ? MAX_TIME_REMAINING_IN_SESSION : thirdSessionLength;

  const message = useMemo(() => {
    if (totalSeconds <= 0) {
      return t('common.session.expired');
    }

    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor((totalSeconds % 3600) % 60);

    return minutes > 0
      ? t('common.session.expiry_minutes', minutes, seconds)
      : t('common.session.expiry_seconds', seconds);
  }, [totalSeconds]);

  // Idle Functions
  const onIdle = () => {
    // On Bulk Import page the sessions should be renewed
    // Used by issuance when importing enrollments
    if (isBulkAdd) {
      handleRenewal();
    } else {
      logoutJWT(true);
    }
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { activate, isPrompted, isIdle } = useIdleTimer({
    crossTab: true,
    onIdle,
    onPrompt,
    promptBeforeIdle,
    syncTimers: 200,
    timeout
  });

  const handleRenewal = useCallback(async (): Promise<void> => {
    setIsRenewing(true);
    try {
      // Renew session here
      const renewedJWT = await renewJWTToken();
      updateRenewedJWTToken(renewedJWT);
      setOpen(false);
      activate();
    } catch (error) {
      console.error('Session renewal failed:', error);
      logoutJWT(true);
    } finally {
      setIsRenewing(false);
    }
  }, [activate]);

  // Automatic session renewal
  useEffect(() => {
    // Renew the session if the user is active and there is only few seconds left before expiry
    const renew = async (): Promise<void> => {
      const userIsActive = !isPrompted() && !isIdle();

      if (totalSeconds <= 0) {
        // If we're not authenticated to a system application then log the user out
        if (!isSystemLoggedIn()) {
          logoutJWT(true);
          history.push('/logout');
        } else {
          // Otherwise let the AuthProvider handle the redirect
          logoutJWT(true);
        }
        return;
      }

      if (totalSeconds < AUTO_RENEW_THRESHOLD && userIsActive && !isRenewing) {
        await handleRenewal();
        return;
      }
    };

    if (isLoggedIn()) {
      renew();
    }
  }, [totalSeconds, isPrompted, isIdle, history, handleRenewal]);

  // Timer to update countdowns
  useEffect(() => {
    const interval = setInterval(() => {
      setTotalSeconds(Math.round((getSessionExpiryTime() - Date.now()) / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <FinalBasicDialog
      id="app-idle-dialog"
      dialogProps={{
        className: classes.root
      }}
      onClose={handleRenewal}
      primaryButtonLabel={t('button.continue')}
      disablePrimaryButton={isRenewing}
      primaryOnly
      title={t('button.warning')}>
      <p>{message}</p>
    </FinalBasicDialog>
  );
};

export default IdleDialog;
